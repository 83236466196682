import SearchPage from "./search-page";
import React from "react";
import { Switch, Route } from "react-router-dom";
import { Project } from "../api/project";
import HomePage from "./home-page";
import FourOhFourPage from "./four-oh-four-page";
import RouteableProjectView from "./routeable-project-view";
import { ApiClient } from "../api/api-client";
import { UnsavedProject } from "../api/unsaved-project";
import { TemplateTagKey } from "../template-tag-key";
import ProjectsView from "./projects-view";
import { Font } from "../font";
import { TemplateTag } from "../template-tag";
import { ProductConfigurationPresentationSource } from "../product-configuration-presentation-source";
import { ProductConfigurationIdea } from "../product-configuration-idea";
import { ProductConfigurationReference } from "../api/product-configuration-reference";


class Produktdatenbank extends React.Component<ProduktdatenbankProps, State>
{
    public props : ProduktdatenbankProps;

    public state : State =
    {
        projects: [],
        projectsHaveBeenLoaded: false,
        availableDocumentTemplateIds: [],
        availableDocumentTemplateIdsHaveBeenLoaded: false,
        configurationPresentationSourcesPerProject: new Map(),
        configurationPresentationSourcesPerProjectHaveBeenLoaded: false,
        configurationPresentationSourcesHaveFailed: false,
        availableConfigurationIdeas: [],
        availableConfigurationIdeasHaveBeenLoaded: false,
        availableConfigurationIdeasHaveFailed: false,
        selectedConfigurationIndex: null
    };


    public constructor(props : ProduktdatenbankProps)
    {
        super(props);
        this.props = props;
    }


    public async componentDidMount()
    {
        // no need for async functions to await each other
        const projectsPromise = this.loadProjects();
        this.loadDocumentTemplates();
        this.loadAvailableProductConfigurationIdeas();
        this.loadConfigurationPresentationSourcesPerProject(await projectsPromise);
    }


    public render() : JSX.Element
    {
        return (
            <Switch>
                <Route path="/search">
                    <SearchPage
                        availableConfigurationIdeas={this.state.availableConfigurationIdeas}
                        availableConfigurationIdeasHaveBeenLoaded={this.state.availableConfigurationIdeasHaveBeenLoaded}
                        onClearCacheClick={() => this.props.onClearCacheClick()}
                    />
                </Route>

                <Route path="/projects/:id">
                    <RouteableProjectView
                        availableConfigurationIdeasHaveFailed={this.state.availableConfigurationIdeasHaveFailed}
                        configurationPresentationSourcesHaveFailed={this.state.configurationPresentationSourcesHaveFailed}
                        availableProjects={this.state.projects}
                        availableProjectsHaveBeenLoaded={this.state.projectsHaveBeenLoaded}
                        availableDocumentTemplateIds={this.state.availableDocumentTemplateIds}
                        availableDocumentTemplateIdHaveBeenLoaded={this.state.availableDocumentTemplateIdsHaveBeenLoaded}
                        availableFonts={this.props.availableFonts}
                        availableFontsHaveBeenLoaded={this.props.availableFontsHaveBeenLoaded}
                        configurationPresentationSourcesPerProject={this.state.configurationPresentationSourcesPerProject}
                        configurationPresentationSourcesPerProjectHaveBeenLoaded={this.state.configurationPresentationSourcesPerProjectHaveBeenLoaded}
                        availableConfigurationIdeas={this.state.availableConfigurationIdeas}
                        availableConfigurationIdeasHaveBeenLoaded={this.state.availableConfigurationIdeasHaveBeenLoaded}
                        templateSetTagKeys={this.props.templateSetTagKeys}
                        defaultTemplateTags={this.props.defaultTemplateTags}
                        defaultTemplateTagsHaveBeenLoaded={this.props.defaultTemplateTagsHaveBeenLoaded}
                        selectedConfigurationIndex={this.state.selectedConfigurationIndex}
                        onProductConfigurationReferenceAddition={(changingProject, addingReference) => this.handleProductConfigurationReferenceAddition(changingProject, addingReference)}
                        onProductConfigurationReferenceRemoval={(changingProject, removingReference) => this.handleProductConfigurationReferenceRemoval(changingProject, removingReference)}
                        onProjectUpdate={() => this.setState({ projects: this.state.projects })}
                        onProductConfigurationSelection={selectingIndex => this.handleProductConfigurationSelection(selectingIndex)}
                        onProjectViewLeave={() => this.handleProjectViewLeave()}
                        onClearCacheClick={() => this.props.onClearCacheClick()}
                    />
                </Route>

                <Route path="/projects">
                    <ProjectsView onClearCacheClick={() => this.props.onClearCacheClick()}
                        projects={this.state.projects}
                        configurationPresentationSourcesPerProject={this.state.configurationPresentationSourcesPerProject}
                        configurationPresentationSourcesPerProjectHaveBeenLoaded={this.state.configurationPresentationSourcesPerProjectHaveBeenLoaded}
                        availableConfigurationIdeas={this.state.availableConfigurationIdeas}
                        availableConfigurationIdeasHaveBeenLoaded={this.state.availableConfigurationIdeasHaveBeenLoaded}
                        onProjectAddition={projectName => this.handleProjectAddition(projectName)}
                        onProjectNameUpdate={(updatingProject) => this.handleProjectNameUpdate(updatingProject)}
                        onProjectRemoval={removingProject => this.handleProjectRemoval(removingProject)}
                    />
                </Route>

                <Route exact path="/">
                    <HomePage />
                </Route>

                <Route path="*">
                    <FourOhFourPage />
                </Route>
            </Switch>
        );
    }


    handleProductConfigurationSelection(selectingIndex: number | null): void | Promise<void>
    {
        this.setState({ selectedConfigurationIndex: selectingIndex });
    }


    async handleProductConfigurationReferenceAddition(changingProject : Project, addingReference: ProductConfigurationReference)
    {
        const { configurationPresentationSourcesPerProject } = this.state;

        const createdSubpath = await this.props.apiClient.addProductConfigurationReferenceToProject(changingProject.Id, addingReference);

        changingProject.addProductConfigurationReferenceSubpath(createdSubpath);

        const addingConfigurationPresentationSource = new ProductConfigurationPresentationSource(addingReference, []);
        configurationPresentationSourcesPerProject.get(changingProject)?.push(addingConfigurationPresentationSource);

        const indexOfAddedReference = (changingProject.ProductConfigurationReferenceSubpathsCount - 1);

        this.setState({
            configurationPresentationSourcesPerProject: new Map(configurationPresentationSourcesPerProject),
            selectedConfigurationIndex: indexOfAddedReference
        });
    }


    async handleProductConfigurationReferenceRemoval(changingProject : Project, removingReference : ProductConfigurationReference)
    {
        const { configurationPresentationSourcesPerProject, selectedConfigurationIndex } = this.state;

        const projectPresentationSources = configurationPresentationSourcesPerProject.get(changingProject) ?? [];
        const removingReferenceIndex = projectPresentationSources?.findIndex(presentationSource => presentationSource.Reference.checkIfEquals(removingReference));
        if(removingReferenceIndex == null) throw new Error();

        let selectedConfigurationPresentationSource : ProductConfigurationPresentationSource | null = null;
        if(selectedConfigurationIndex != null) selectedConfigurationPresentationSource = projectPresentationSources[selectedConfigurationIndex];

        const removingReferenceSubpath = changingProject.ProductConfigurationReferenceSubpaths[removingReferenceIndex];

        await this.props.apiClient.removeProductConfigurationReferenceFromProject(changingProject.Id, removingReferenceSubpath);

        let changingProjectConfigurationPresentationSources = configurationPresentationSourcesPerProject.get(changingProject) ?? [];
        changingProjectConfigurationPresentationSources = changingProjectConfigurationPresentationSources.filter(presentationSource =>
        {
            return !presentationSource.Reference.checkIfEquals(removingReference);
        });
        configurationPresentationSourcesPerProject.set(changingProject, changingProjectConfigurationPresentationSources);

        changingProject.removeProductConfigurationReferenceSubpath(removingReferenceSubpath);

        const indexOfPreviouslySelectedConfiguration = changingProjectConfigurationPresentationSources.findIndex(source => source === selectedConfigurationPresentationSource);
        const newSelectedConfigurationIndex = indexOfPreviouslySelectedConfiguration >= 0 ? indexOfPreviouslySelectedConfiguration : null;

        this.setState({
            configurationPresentationSourcesPerProject: new Map(configurationPresentationSourcesPerProject),
            selectedConfigurationIndex: newSelectedConfigurationIndex
        });
    }


    handleProjectViewLeave()
    {
        this.setState({ selectedConfigurationIndex: null });
    }


    private async handleProjectAddition(projectName : string)
    {
        const unsavedProject = UnsavedProject.BuildFromName(projectName);

        const projectId = await this.props.apiClient.createProject(unsavedProject);

        const createdProject = new Project(projectId, unsavedProject);

        const newProjects = [...this.state.projects, createdProject];

        const { configurationPresentationSourcesPerProject } = this.state;
        const updatedConfigurationPresentationSourcesPerProject = new Map(configurationPresentationSourcesPerProject);
        updatedConfigurationPresentationSourcesPerProject.set(createdProject, []);

        this.setState({
            projects: newProjects,
            configurationPresentationSourcesPerProject: updatedConfigurationPresentationSourcesPerProject
        });
    }

    
    private async handleProjectNameUpdate(updatingProject : Project) : Promise<void>
    {
        const projectId = updatingProject.Id;

        await this.props.apiClient.updateProject(projectId, { Name: updatingProject.Name });

        this.setState({ projects: this.state.projects });
    }


    private async handleProjectRemoval(removingProject : Project) : Promise<void>
    {
        const removalQuestion = `Soll das Projekt "${removingProject.Name}" mit ${removingProject.ProductConfigurationReferenceSubpathsCount} Konfigurationen wirklich gelöscht werden?"`;
        const userWantsToRemove = window.confirm(removalQuestion);
        if(!userWantsToRemove) return;

        await this.props.apiClient.removeProject(removingProject.Id);

        const newProjects = this.state.projects.filter(project => project !== removingProject);

        const { configurationPresentationSourcesPerProject } = this.state;
        const updatedConfigurationPresentationSourcesPerProject = new Map(configurationPresentationSourcesPerProject);
        updatedConfigurationPresentationSourcesPerProject.delete(removingProject);

        this.setState({
            projects: newProjects,
            configurationPresentationSourcesPerProject: updatedConfigurationPresentationSourcesPerProject
        });
    }


    private async loadProjects() : Promise<Project[]>
    {
        const projects = await this.props.apiClient.requestProjects();
        
        this.setState(
        {
            projects,
            projectsHaveBeenLoaded: true
        });

        return projects;
    }


    private async loadDocumentTemplates()
    {
        const availableDocumentTemplateIds = await this.props.apiClient.requestTemplates();

        this.setState(
        {
            availableDocumentTemplateIds,
            availableDocumentTemplateIdsHaveBeenLoaded : true
        });
    }


    private async loadAvailableProductConfigurationIdeas()
    {
        const availableConfigurationIdeas = await this.props.apiClient.requestAllProductConfigurationIdeas();
    
        this.setState({
            availableConfigurationIdeas,
            availableConfigurationIdeasHaveBeenLoaded: true
        });
    }


    private async loadConfigurationPresentationSourcesPerProject(projects : Project[])
    {
        const projectsPresentationSourcesLoadedPromises = projects.flatMap(project =>
        {
            return project.ProductConfigurationReferenceSubpaths.map(subpath =>
            {
                return this.props.apiClient.requestProductConfigurationPresentationSource(project.Id, subpath).then(presentationSource =>
                ({
                    project,
                    presentationSource
                }));
            });
        });

        const projectsPresentationSources = await Promise.all(projectsPresentationSourcesLoadedPromises);

        const configurationPresentationSourcesPerProject = new Map<Project, ProductConfigurationPresentationSource[]>();
        for(const project of projects) configurationPresentationSourcesPerProject.set(project, []);

        for(const projectPresentationSource of projectsPresentationSources)
        {
            const { project, presentationSource } = projectPresentationSource;

            const presentationSourcesPerProject = configurationPresentationSourcesPerProject.get(project);
            presentationSourcesPerProject?.push(presentationSource);
        }

        this.setState({
            configurationPresentationSourcesPerProject,
            configurationPresentationSourcesPerProjectHaveBeenLoaded: true
        });

        const configurationPresentationSourcesExist = Array.from(configurationPresentationSourcesPerProject.values()).length > 0;

        if(configurationPresentationSourcesExist && this.state.selectedConfigurationIndex == null)
        {
            this.setState({ selectedConfigurationIndex: 0 });
        }
    }
}


interface State
{
    projects : Project[];
    projectsHaveBeenLoaded : boolean;
    availableDocumentTemplateIds : string[];
    availableDocumentTemplateIdsHaveBeenLoaded : boolean;
    configurationPresentationSourcesPerProject : Map<Project, ProductConfigurationPresentationSource[]>;
    configurationPresentationSourcesPerProjectHaveBeenLoaded : boolean;
    configurationPresentationSourcesHaveFailed : boolean;
    availableConfigurationIdeas : ProductConfigurationIdea[];
    availableConfigurationIdeasHaveBeenLoaded : boolean;
    availableConfigurationIdeasHaveFailed : boolean;
    selectedConfigurationIndex : number | null;
}


interface ProduktdatenbankProps
{
    availableFonts : Font[];
    availableFontsHaveBeenLoaded : boolean;
    templateSetTagKeys : TemplateTagKey[];
    defaultTemplateTags : TemplateTag[];
    defaultTemplateTagsHaveBeenLoaded : boolean;
    onClearCacheClick() : Promise<void>;
    apiClient : ApiClient;
}


export default Produktdatenbank;