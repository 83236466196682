import React from "react";
import Gallery from "./gallery";
import Page from "./page";
import SearchBar from "./search-bar";
import { Rendering } from "../rendering";
import { ProductConfigurationIdea } from "../product-configuration-idea";


class SearchPage extends React.Component
{
    private static readonly CONFIGURATION =
    {
        CLICK_OPENING_FILE_SIZE_CODE: "original"
    }


    public props : SearchPageProps;

    public state : State =
    {
        searchTerm: null,
        selectedImage: null,
    };


    public constructor(props : SearchPageProps)
    {
        super(props);
        this.props = props;
    }


    public render()
    {
        return (
            <Page className="search-page">

                <h1>Suche</h1>
                <SearchBar
                    isDisabled={!this.props.availableConfigurationIdeasHaveBeenLoaded}
                    configurationReferences={this.props.availableConfigurationIdeas.map(productConfiguration => productConfiguration.Reference)}
                    onSubmit={searchTerm => this.handleSearchBarSubmission(searchTerm)}
                />
                <Gallery onGridImageClicked={image => this.handleGridImageClick(image)} configurationIdea={this.ResultProductConfiguration} />

                <div className="clear-cache-block">
                    <input type="button" value="DAM-System-Cache leeren" onClick={this.props.onClearCacheClick} />
                </div>

            </Page>
        );
    }


    private handleSearchBarSubmission(searchTerm : string)
    {
        this.setState({ searchTerm });
    }


    private get ResultProductConfiguration() : ProductConfigurationIdea | null
    {
        if(!this.state.searchTerm) return null;
        else return this.props.availableConfigurationIdeas.find(configurationIdea =>
        {
            let isSearchedConfiguration = configurationIdea.Reference.DefinitionHash === this.state.searchTerm;
            isSearchedConfiguration = isSearchedConfiguration || configurationIdea.Reference.Name === this.state.searchTerm;

            return isSearchedConfiguration;

        }) ?? null;
    }


    private handleGridImageClick(clickedRendering : Rendering)
    {
        const openingUrl = clickedRendering.getBestPreferredUrl(SearchPage.CONFIGURATION.CLICK_OPENING_FILE_SIZE_CODE);

        if(openingUrl) window.open(openingUrl, "_blank");
    }
}


interface SearchPageProps
{
    availableConfigurationIdeas : ProductConfigurationIdea[];
    availableConfigurationIdeasHaveBeenLoaded : boolean;
    onClearCacheClick(): void | Promise<void>;
}


interface State
{
    searchTerm: string | null;
    selectedImage: Rendering | null;
}


export default SearchPage;