import { ImageOption } from "../image-option";
import { Utils } from "../utils";
import "./text-image-slots-list.css";
import { TextImageDropdown } from "./text-image-dropdown";


export const RichSlotsList = (props : RichSlotsListProps) =>
{
    async function handleSelectionChange(updatedValue : string | null, changedSlotIndex : number)
    {
        const selectedValues = [];
        for(let slotIndex = 0; slotIndex < props.slotsCount; slotIndex++)
        {
            let slotIndexValue;

            if(slotIndex === changedSlotIndex) slotIndexValue = updatedValue;
            else slotIndexValue = props.selectedValues?.[slotIndex] || null;

            selectedValues[slotIndex] = slotIndexValue;
        }

        await props.onSelectionChange?.(selectedValues);
    }
    

    function getLabelOrNothing(text : string | null, htmlFor : string) : JSX.Element | null
    {
        if(text === null) return null;
        else return <label htmlFor={htmlFor}>{text}</label>;
    }


    function getSlots() : JSX.Element[]
    {
        const slots = [];

        for(let slotIndex = 0; slotIndex < props.slotsCount; slotIndex++)
        {
            let labelText = null;
            if(props.slotLabels) labelText = '';
            if(props.slotLabels?.[slotIndex]) labelText = props.slotLabels[slotIndex];

            const selectId = `slots-list-select-${Utils.GenerateRandomUint()}`;

            const slotValue = props.selectedValues?.[slotIndex] ?? null;

            const slot = (
                <li key={slotIndex} className="slot">
                    {getLabelOrNothing(labelText, selectId)}
                    <TextImageDropdown
                        id={selectId}
                        value={slotValue}
                        options={props.options}
                        disabled={!!props.disabled}
                        onInput={() => {}}
                        onSubmit={value => handleSelectionChange(value, slotIndex)}
                    />
                </li>
            );
            slots.push(slot);
        }

        return slots;
    }


    function getLegendOrNothing() : JSX.Element | null
    {
        if(props.listLabel == null) return null;

        return <legend>{props.listLabel}</legend>;
    }


    return (
        <fieldset className="text-image-slots-list">
            {getLegendOrNothing()}
            <ul>
                {getSlots()}
            </ul>
        </fieldset>
    );
}


interface RichSlotsListProps
{
    slotsCount : number;
    options : ImageOption[];
    listLabel? : string;
    slotLabels? : string[];
    selectedValues? : (string | null)[];
    defaultSelection? : string;
    disabled? : boolean;
    onSelectionChange? : (selectedValues : (string | null)[]) => void | Promise<void>
}