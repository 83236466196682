import { TemplateFontTag } from "../template-font-tag";
import { ApiAdapter } from "./api-adapter";
import { ServerApi } from "./server-api";


export class UnsavedProject
{
    private name : string;
    private cameraIds : (string | null)[];
    private fonts : TemplateFontTag[]
    private templateDocumentIds: (string | null)[];
    private productConfigurationReferenceSubpaths: number[];


    public static BuildFromName(name : string)
    {
        return new UnsavedProject(name);
    }


    public static BuildFromBackendProject(backendProject : ServerApi.UnsavedProject) : UnsavedProject
    {
        const name = backendProject.name;
        const cameraIds = backendProject.camera_ids.map(id => id || null);
        const fonts = ApiAdapter.ConvertBackendProjectFonts(backendProject.fonts);
        const templateDocumentIds = backendProject.template_document_ids.map(id => id || null);
        const productConfigurationReferenceSubpaths = backendProject.product_configuration_reference_subpaths;

        const unsavedProject = new UnsavedProject(name, cameraIds, fonts, templateDocumentIds, productConfigurationReferenceSubpaths);
        return unsavedProject;
    }


    protected constructor(
        name : string,
        cameraIds : (string | null)[] = [], 
        fonts : TemplateFontTag[] = [], 
        templateDocumentIds: (string | null)[] = [], 
        productConfigurationReferenceSubpaths: number[] = []
    )
    {
        this.name = name;
        this.cameraIds = cameraIds;
        this.fonts = fonts;
        this.templateDocumentIds = templateDocumentIds;
        this.productConfigurationReferenceSubpaths = productConfigurationReferenceSubpaths;
    }


    public get Name() : string
    {
        return this.name;
    }


    public set Name(value : string)
    {
        this.name = value;
    }


    public get CameraIds() : (string | null)[]
    {
        return this.cameraIds;
    }


    public set CameraIds(value : (string | null)[])
    {
        this.cameraIds = value;
    }


    public get MainCameraId() : string | null
    {
        return this.cameraIds?.[0] ?? null;
    }


    public get Fonts() : TemplateFontTag[]
    {
        return this.fonts;
    }


    public set Fonts(value : TemplateFontTag[])
    {
        this.fonts = value;
    }


    public get TemplateDocumentIds() : (string | null)[]
    {
        return this.templateDocumentIds;
    }


    public set TemplateDocumentIds(value : (string | null)[])
    {
        this.templateDocumentIds = value;
    }


    public get NonNullTemplateDocumentIds() : string[]
    {
        return this.templateDocumentIds.filter(id => (id != null)) as string[];
    }


    public get ProductConfigurationReferenceSubpaths() : number[]
    {
        return this.productConfigurationReferenceSubpaths;
    }


    public get ProductConfigurationReferenceSubpathsCount() : number
    {
        return this.productConfigurationReferenceSubpaths.length;
    }


    public getProductConfigurationReferenceSubpathAt(index : number) : number
    {
        let productConfigurationSubpath = this.productConfigurationReferenceSubpaths[index];
        if(productConfigurationSubpath == null) throw new Error("Accessed product configuration subpaths at invalid index.");

        return productConfigurationSubpath;
    }


    public addProductConfigurationReferenceSubpath(subpath : number)
    {
        this.productConfigurationReferenceSubpaths.push(subpath);
    }


    public removeProductConfigurationReferenceSubpath(removingSubpath : number)
    {
        this.productConfigurationReferenceSubpaths = this.productConfigurationReferenceSubpaths.filter(subpath => subpath !== removingSubpath);
    }
}