import { FunctionComponent, ReactElement, ReactNode } from "react";
import "./editable-block.css";


export const EditableBlock : FunctionComponent<EditableBlockProps> = props =>
{
    function getRemovalButtonOrNothing() : JSX.Element | null
    {
        if(!props.removability) return null;

        return (
            <button
                className="removal-button"
                disabled={!props.removability?.current}
                onClick={ props.onRemovalClick}
            >
                <img src="/images/icon-delete.svg" alt="Entfernen" />
            </button>
        );
    }

    return (
        <div className="editable-block">
            {props.content}
            {getRemovalButtonOrNothing()}
            {props.mutationElements?.mutabilityToggle}
        </div>
    );
};


export interface EditableBlockProps
{
    isBeingEdited : boolean;
    content : ReactNode;
    removability : { current : boolean } | null;
    mutationElements? : VariableListItem.MutationElements;
    onRemovalClick() : void | Promise<void>;
}


export namespace VariableListItem
{
    export interface MutationElements
    {
        valueMutationInput : ReactElement;
        mutabilityToggle? : ReactElement;
    }
}