import Color from "color";
import React from "react";
import { Utils } from "../utils";
import { ColorTextInput } from "./color-text-input";
import ColorTextInputGroup from "./color-text-input-group";
import "./color-text-input-group.css";


export class ColorTextInputGroupManager extends React.Component<ColorTextInputGroupManagerProps, State>
{
    public props : ColorTextInputGroupManagerProps;
    public state : State;
    
    private readonly textInputId : string;


    public static PrependHashsignIfMissing(text : string) : string
    {
        const hashsignIsMissing = (!text.startsWith('#'));

        if(hashsignIsMissing) return ('#' + text);
        else return text;
    }


    public constructor(props : ColorTextInputGroupManagerProps)
    {
        super(props);
        this.props = props;

        this.state = { inputValue: props.color?.hex() ?? null };

        this.textInputId = ColorTextInput.GenerateInputId();
    }


    public render() : JSX.Element
    {
        return (            
            <ColorTextInputGroup
                textInputId={this.textInputId}
                label={this.props.label}
                inputValue={this.state.inputValue}
                onInput={lowercaseValue => this.handleInputInput(lowercaseValue)}
                onReceivedDrop={lowercaseValue => this.handleInputReceivedDrop(lowercaseValue)}
                onSubmit={async () => this.submitColor()}
            />
        );
    }


    private async handleInputInput(lowercaseValue : string) : Promise<void>
    {
        const adaptedInputText = Utils.PrependHashsignIfMissing(lowercaseValue);

        await new Promise(resolve =>
        {
            this.setState({ inputValue: adaptedInputText }, (resolve as () => void));
        });

        const inputColor = Utils.CheckIfColorIsValid(adaptedInputText) ? new Color(adaptedInputText) : null;

        this.props.onInput?.(inputColor);
    }


    private async handleInputReceivedDrop(lowercaseValue : string)
    {
        const adaptedInputText = Utils.PrependHashsignIfMissing(lowercaseValue);

        let inputHexColor : string | null = null;
        const colorIsValid = Utils.CheckIfColorIsValid(adaptedInputText);
        if(colorIsValid) inputHexColor = adaptedInputText;

        await new Promise(resolve =>
        {
            this.setState({ inputValue: inputHexColor }, (resolve as () => void));
        });
    }


    private async submitColor()
    {
        const inputValue = this.state.inputValue;
        
        const colorIsValid = Utils.CheckIfColorIsValid(inputValue);

        const inputColor = colorIsValid ? new Color(inputValue ?? '') : null;
        if(!inputColor) this.setState({ inputValue: null });
        
        await this.props.onSubmit(inputColor);
    }
};


export interface ColorTextInputGroupManagerProps
{
    label : string;
    color : Color | null;
    onSubmit(updatedColor : Color | null) : void | Promise<void>;
    onInput?(updatedColor : Color | null) : void | Promise<void>;
}


interface State
{
    inputValue : string | null;
}