import React, { JSXElementConstructor, ReactElement, ReactFragment } from "react";
import { Project } from "../../api/project";
import { MutabilityToggle } from "../mutability-toggle";
import { ProjectsListItemLabel } from "./projects-list-item-label";
import TextInputDropdown from "../text-input-dropdown";
import { EditableCard, EditableCardProps } from "../editable-card";
import { EditableGridList } from "./editable-grid-list";
import { ProductConfigurationPresentationSource } from "../../product-configuration-presentation-source";
import { ProductConfigurationIdea } from "../../product-configuration-idea";
import "./projects-list.css";


export class ProjectsList extends React.Component<ProjectsListProps, State>
{
    public props : ProjectsListProps;
    public state : State;


    public constructor(props : ProjectsListProps)
    {
        super(props);
        this.props = props;

        this.state = {
            editingItemIndex: null,
            editInputValue: null,
            addInputValue: null
        };
    }
    

    private get AdditionItem() : ReactFragment
    {
        return (
            <React.Fragment>
                <TextInputDropdown
                    disabled={false} 
                    id="projects-list-add-input"
                    onInput={value => this.handleAdditionInputInput(value)}
                    onSubmit={() => this.handleAdditionInputSubmission()}
                    options={[]}
                    value={this.state.addInputValue}
                />
                <button
                    className="add-button"
                    onClick={() => this.handleAdditionInputSubmission()}
                    disabled={!this.state.addInputValue}
                >
                    <img src="/images/icon-plus-circle.svg" alt="Add"/>
                </button>
            </React.Fragment>
        );
    }


    public render()
    {
        return (
            <EditableGridList
                items={this.Items}
                additionalClassName="projects-list"
                additionItem={this.AdditionItem}
                isEnabled={this.IsEnabled}
                listLabel="Projekte"
            />
        );
    }


    private get IsBeingEdited() : boolean
    {
        return (this.state.editingItemIndex != null);
    }


    private get IsEnabled() : boolean
    {
        return (this.props.availableConfigurationIdeasHaveBeenLoaded && this.props.configurationPresentationSourcesPerProjectHaveBeenLoaded);
    }


    private get Items() : ReactElement<EditableCardProps, JSXElementConstructor<typeof EditableCard>>[]
    {
        return this.props.projects.map((project, itemIndex) =>
        {
            const configurationPresentationSources = this.props.configurationPresentationSourcesPerProject.get(project);
            const previewingConfigurations = configurationPresentationSources?.slice(0, ProjectsListItemLabel.COMPONENT_CONFIGURATION.PREVIEW_CONFIGURATIONS_COUNT) ?? [];
            const mainCameraId = project.MainCameraId;

            const previewingProductConfigurationsMainImageUrls = previewingConfigurations.map(configuration =>
            {
                const correspondentConfigurationIdea = this.props.availableConfigurationIdeas.find(idea => idea.checkIfReferenceMatches(configuration.Reference));

                if(!mainCameraId || !correspondentConfigurationIdea) return null;
                return correspondentConfigurationIdea.getRenderingInPreferredFormatUrl(mainCameraId, "original");

            }).filter(url => (url != null)) as string[];

            const label = (
                <ProjectsListItemLabel.Component
                    text={project.Name}
                    imageUrls={previewingProductConfigurationsMainImageUrls}
                    imagesAreLoading={!this.props.availableConfigurationIdeasHaveBeenLoaded || !this.props.configurationPresentationSourcesPerProjectHaveBeenLoaded}
                    click={{
                        isPossible: (this.state.editingItemIndex !== itemIndex),
                        handle: () => this.props.onProjectSelection(project)
                    }}
                    key={itemIndex}
                />
            );

            const itemIsBeingEdited = (this.state.editingItemIndex === itemIndex);


            const mutabilityToggle = (
                <MutabilityToggle
                    isOn={itemIsBeingEdited}
                    isEnabled={this.IsEnabled && ((itemIsBeingEdited && !!this.state.editInputValue) || (!this.IsBeingEdited))}
                    onToggle={() => this.handleListItemMutabilityChange(itemIndex)}
                />
            );

            const valueMutationInput = (
                <TextInputDropdown
                    disabled={false} 
                    id={`projects-list-edit-input-${itemIndex}`}
                    onInput={value => this.handleListItemInputInput(value)}
                    onSubmit={() => this.handleListItemInputSubmission()}
                    options={[]}
                    value={this.state.editInputValue}
                />
            );

            return (
                <li key={itemIndex}>
                    <EditableCard
                        content={label}
                        removability={{ current: (this.state.editingItemIndex == null) }}
                        onRemovalClick={async () => await this.props.onProjectRemoval(project)}
                        mutationElements={{ valueMutationInput, mutabilityToggle }}
                        isBeingEdited={itemIsBeingEdited}
                    />
                </li>
            );
        });
    }

    
    private async handleListItemInputInput(value : string | null)
    {
        this.setState({ editInputValue: value });
    }

    
    private async handleListItemInputSubmission()
    {
        const { editingItemIndex, editInputValue } = this.state;

        if(editingItemIndex == null) throw new Error("Submitted list item while none was being edited.");

        const updatingProject = this.props.projects[editingItemIndex];

        if(!editInputValue) throw new Error("Tried to save a project without name.");
        updatingProject.Name = editInputValue;

        await this.props.onProjectNameUpdate(updatingProject);

        this.setState({
            editInputValue: null,
            editingItemIndex: null
        });
    }


    private async handleListItemMutabilityChange(itemIndex: number)
    {
        const hasBecomeMutable = (this.state.editingItemIndex == null);

        if(hasBecomeMutable)
        {
            this.setState({
                editingItemIndex: itemIndex,
                editInputValue: this.props.projects[itemIndex].Name
            });
        }
        else await this.handleListItemInputSubmission();
    }


    private async handleAdditionInputSubmission()
    {
        const { addInputValue } = this.state;
        if(!addInputValue) throw new Error("Tried to add a project without name.");

        await this.props.onProjectAddition(addInputValue);

        this.setState({ addInputValue: null });
    }


    private async handleAdditionInputInput(value: string | null)
    {
        this.setState({ addInputValue: value });
    }
}


interface State
{
    editingItemIndex : number | null;
    editInputValue : string | null;
    addInputValue : string | null;
}


interface ProjectsListProps
{
    projects : Project[];
    configurationPresentationSourcesPerProject: Map<Project, ProductConfigurationPresentationSource[]>;
    configurationPresentationSourcesPerProjectHaveBeenLoaded: boolean;
    availableConfigurationIdeas: ProductConfigurationIdea[];
    availableConfigurationIdeasHaveBeenLoaded: boolean;
    onProjectSelection(selectingProject : Project) : void | Promise<void>;
    onProjectAddition(addingProjectName : string) : void | Promise<void>;
    onProjectRemoval(removingProject : Project) : void | Promise<void>;
    onProjectNameUpdate(updatedProject : Project) : void | Promise<void>;
}