import ClickableRendering from "./clickable-rendering";
import { Rendering } from "../rendering";
import Masonry from 'react-masonry-css'
import "./gallery.css";
import { ProductConfigurationIdea } from "../product-configuration-idea";


const Gallery = (props : GalleryProps) =>
{
    async function handleImageClick(clickedRendering : Rendering)
    {
        await props.onGridImageClicked?.(clickedRendering);
    }


    function getImages() : JSX.Element[]
    {
        if(!props.configurationIdea) return [];
        return props.configurationIdea.Renderings.map((rendering, index) =>
            <ClickableRendering key={index} rendering={rendering} onClicked={handleImageClick} />
        );
    }


    return (
        <div className="gallery">
            <Masonry
                breakpointCols={3}
                className="masonry-grid"
                columnClassName="masonry-grid-column">
                {getImages()}
            </Masonry>
        </div>
    );
}


interface GalleryProps
{
    configurationIdea : ProductConfigurationIdea | null;
    onGridImageClicked?(resource : Rendering) : void | Promise<void>;
}


export default Gallery;