import { ApiAdapter } from "./api-adapter";
import { ServerApi } from "./server-api";
import { UnsavedProject } from "./unsaved-project";


export class Project extends UnsavedProject
{
    private id : number;


    public static BuildFromBackendProject(backendProject : ServerApi.Project) : Project
    {
        const name = backendProject.name;
        const cameraIds = backendProject.camera_ids.map(id => id || null);
        const fonts = ApiAdapter.ConvertBackendProjectFonts(backendProject.fonts);
        const templateDocumentIds = backendProject.template_document_ids.map(id => id || null);
        const productConfigurationReferenceSubpaths = backendProject.product_configuration_reference_subpaths;

        const unsavedProject = new UnsavedProject(name, cameraIds, fonts, templateDocumentIds, productConfigurationReferenceSubpaths);

        const id = backendProject.id;

        const project = new Project(id, unsavedProject);
        return project;
    }


    public constructor(id : number, unsavedProject : UnsavedProject)
    {
        const name = unsavedProject.Name;
        const cameraIds = unsavedProject.CameraIds;
        const fonts = unsavedProject.Fonts;
        const templateDocumentIds = unsavedProject.TemplateDocumentIds;
        const productConfigurationReferenceSubpaths = unsavedProject.ProductConfigurationReferenceSubpaths;

        super(name, cameraIds, fonts, templateDocumentIds, productConfigurationReferenceSubpaths);
        this.id = id;
    }


    public get Id() : number
    {
        return this.id;
    }


    public set Id(value : number)
    {
        this.id = value;
    }
}


