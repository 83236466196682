import "./app.css";
import React from "react";
import { Switch, Route } from "react-router-dom";
import { ApiClient } from "../api/api-client";
import { TemplateTagKey } from "../template-tag-key";
import { LoginView } from "./login-view";
import Produktdatenbank from "./produktdatenbank";
import TemplateEditor from "./template-editor";
import { Font } from "../font";
import { TemplateTag } from "../template-tag";


class App extends React.Component
{
    public static readonly PREFERRED_FILE_EXTENSIONS = Object.freeze(["webp", "png"]);
    public static readonly IMAGE_OPENING_FILE_EXTENSION = "png";
    public static readonly IMAGE_OPENING_SIZE_CODE = "original";

    public state : State =
    {
        isLoggedIn: false,
        loginStateHasBeenLoaded: false,
        availableFonts: [],
        availableFontsHaveBeenLoaded: false,
        templateSetTagKeys: [],
        defaultTemplateTags: [],
        defaultTemplateTagsHaveBeenLoaded: false
    };

    private readonly apiClient : ApiClient;


    public constructor(props : any)
    {
        super(props);

        this.apiClient = new ApiClient();
    }


    public async componentDidMount()
    {
        const loginState = await this.apiClient.requestLoginState();
        this.setState({
            isLoggedIn: loginState.isLoggedIn,
            loginStateHasBeenLoaded: true
        });

        if(!loginState.isLoggedIn) return;

        this.loadFonts();
        this.loadTemplateSetTagKeys();
        this.loadDefaultTemplateTags();
    }


    private async loadDefaultTemplateTags()
    {
        const defaultTemplateTags = await this.apiClient.requestDefaultTemplateTags();
        
        this.setState({ defaultTemplateTags, defaultTemplateTagsHaveBeenLoaded: true });
    }


    public render() : JSX.Element
    {
        if(!this.state.loginStateHasBeenLoaded)
        {
            return (
                <div className="app">
                    <span className="loading-indicator">Authentifiziere ...</span>
                </div>
            );
        }

        if(!this.state.isLoggedIn && document.location.pathname !== "/login")
        {
            const actualUrl = document.location.href;

            window.history.pushState({}, '', "login?redirect=" + encodeURI(actualUrl));

            return (
                <div className="app">
                    <LoginView onLogin={(username, password) => this.handleLogin(username, password)} />
                </div>
            );
        }

        return (
            <div className="app">
                <Switch>
                    <Route path="/login">
                        <LoginView onLogin={(username, password) => this.handleLogin(username, password)} />
                    </Route>

                    <Route path="/template-editor">
                        <TemplateEditor
                            availableFonts={this.state.availableFonts}
                            availableFontsHaveBeenLoaded={this.state.availableFontsHaveBeenLoaded}
                            templateSetTagKeys={this.state.templateSetTagKeys}
                            onClearCacheClick={() => this.handleClearCacheClick()}
                        />
                    </Route>

                    <Route path="*">
                        <Produktdatenbank
                            availableFonts={this.state.availableFonts}
                            availableFontsHaveBeenLoaded={this.state.availableFontsHaveBeenLoaded}
                            defaultTemplateTags={this.state.defaultTemplateTags}
                            defaultTemplateTagsHaveBeenLoaded={this.state.defaultTemplateTagsHaveBeenLoaded}
                            templateSetTagKeys={this.state.templateSetTagKeys}
                            onClearCacheClick={() => this.handleClearCacheClick()}
                            apiClient={this.apiClient}
                        />
                    </Route>
                </Switch>
            </div>
        );
    }


    private async handleClearCacheClick(): Promise<void>
    {
        try
        {
            await this.apiClient.clearDamSystemCache();
            alert("✔️ DAM-System-Cache geleert. Lade Seite neu.");
            document.location.reload();
        }
        catch(error)
        {
            console.error(error);
            alert("❌ Fehler: Konnte DAM-System-Cache nicht leeren.");
        }
    }


    private async handleLogin(username : string, password : string)
    {
        const loginSucceeded = await this.apiClient.login(username, password);

        if(loginSucceeded)
        {
            const currentSearchParams = new URLSearchParams(document.location.search);

            const redirectUrl = currentSearchParams.get("redirect");

            if(redirectUrl) document.location.href = redirectUrl;
            else
            {
                const homePageUrl = document.location.origin;
                document.location.href = homePageUrl;
            }
        }
    }


    private async loadFonts()
    {
        const availableFonts = await this.apiClient.requestFonts();

        const cssFontFaceRules = availableFonts.map(font => font.CssFontFaceRule);
        const cssFontFaceStyleElementContent = cssFontFaceRules.join("\r\n\r\n");        

        const fontsFaceStyleElement = document.createElement("style");
        fontsFaceStyleElement.innerHTML = cssFontFaceStyleElementContent;

        window.document.head.append(fontsFaceStyleElement);

        this.setState(
        {
            availableFonts,
            availableFontsHaveBeenLoaded : true
        });
    }


    private async loadTemplateSetTagKeys()
    {
        const templateSetTagKeys = await this.apiClient.requestTemplateSetTagKeys();
        this.setState({ templateSetTagKeys });
    }
}


interface State
{
    isLoggedIn : boolean;
    loginStateHasBeenLoaded : boolean;
    availableFonts : Font[];
    availableFontsHaveBeenLoaded : boolean;
    templateSetTagKeys : TemplateTagKey[];
    defaultTemplateTags : TemplateTag[];
    defaultTemplateTagsHaveBeenLoaded : boolean;
}


export default App;