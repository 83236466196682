import "./product-configurations-preview.css"
import { TagType, TemplateTagKey } from "../template-tag-key";
import { CollapsibleBlock } from "./collapsible-block";
import { ColorsList } from "./colors-list";
import ColorMappingsList from "./color-mappings-list";
import ProductConfigurationPreviewTopBlock from "./product-configuration-preview-top-block";
import { ProductConfigurationPresentationSource } from "../product-configuration-presentation-source";
import { ProductConfigurationIdea } from "../product-configuration-idea";
import { TemplateTag } from "../template-tag";
import { TemplateTagFactory } from "../template-tag-factory";


const ProductConfigurationsPreview = (props : ProductConfigurationsPreviewProps) =>
{
    function handleTagChange(changedTag : TemplateTag, changedPresentationSource : ProductConfigurationPresentationSource)
    {
        const tagInPresentationSource = changedPresentationSource.TemplateFields.find(field => field.referenceEqualsTag(changedTag));
        
        if(tagInPresentationSource) tagInPresentationSource.fillWith(changedTag);
        else changedPresentationSource.TemplateFields = [...changedPresentationSource.TemplateFields, changedTag];

        props.onColorTemplateFieldsChange(changedPresentationSource);
    }


    function handleProductConfigurationSelectionByPresentationSource(presentationSource: ProductConfigurationPresentationSource)
    {
        const selectedIndex = props.configurationPresentationSources.indexOf(presentationSource);
        if(selectedIndex < 0) throw new Error();
        
        props.onProductConfigurationSelected(selectedIndex);
    }


    function getTemplateSetColorTagSlots(allTemplateSetColorTagKeys : TemplateTagKey[]) : TemplateTagKey[]
    {
        return allTemplateSetColorTagKeys.filter(tagKey => [TagType.Color, TagType.MultiColor].includes(tagKey.tagType));
    }


    function buildTemplateColorTags(colorTagSlots : TemplateTagKey[], idea : ProductConfigurationIdea | null, presentationSource : ProductConfigurationPresentationSource)
    {
        const templateTagFactory = new TemplateTagFactory();

        const initializingColorTags = colorTagSlots.map(slot => templateTagFactory.createTemplateTagFromSlot(slot));

        const ideaBasedDefaultTags = idea?.DefaultTemplateTags ?? [];

        for(const initializingColorTag of initializingColorTags)
        {
            const staticDefaultTag = props.defaultTemplateTags.find(tag => tag.referenceEqualsTag(initializingColorTag));
            if(staticDefaultTag) initializingColorTag.fillWith(staticDefaultTag);

            const ideaBasedDefaultTag = ideaBasedDefaultTags.find(tag => tag.referenceEqualsTag(initializingColorTag));
            if(ideaBasedDefaultTag?.HasInitializedValue) initializingColorTag.fillWith(ideaBasedDefaultTag);

            const actuallyDefinedTag = presentationSource.TemplateFields.find(tag => tag.referenceEqualsTag(initializingColorTag));
            if(actuallyDefinedTag?.HasInitializedValue) initializingColorTag.fillWith(actuallyDefinedTag);
        }

        return initializingColorTags;
    }


    function getColorInputsLists() : JSX.Element[]
    {
        return props.configurationPresentationSources.map(configurationPresentationSource =>
        {
            const configurationIdea = props.availableConfigurationIdeas.find(idea => idea.checkIfReferenceMatches(configurationPresentationSource.Reference)) ?? null;

            const colorTagSlots = getTemplateSetColorTagSlots(props.templateSetTagSlots);

            const colorTags = buildTemplateColorTags(colorTagSlots, configurationIdea, configurationPresentationSource);

            return (
                <ColorMappingsList
                    colorTags={colorTags}
                    colorSuggestionsWithSource={configurationIdea?.NamedInspirationColors ?? []}
                    onChange={changedTag => handleTagChange(changedTag, configurationPresentationSource)}
                />
            );
        });
    }


    function getInspirationColorsLists() : JSX.Element[]
    {
        return props.configurationPresentationSources.map(configurationPresentationSource =>
        {
            const configurationIdea = props.availableConfigurationIdeas.find(idea => idea.checkIfReferenceMatches(configurationPresentationSource.Reference)) ?? null;

            return (
                <ColorsList
                    mainColor={configurationIdea?.Inspiration?.backgroundColor ?? null}
                    listColors={configurationIdea?.Inspiration?.productColors ?? []}
                />
            );
        });
    }


    function getProductConfigurationsPreviewTopBlocks() : JSX.Element[]
    {
        return props.configurationPresentationSources.map((configurationPresentationSource, index) =>
        {
            const configurationIdea = props.availableConfigurationIdeas.find(idea => idea.checkIfReferenceMatches(configurationPresentationSource.Reference)) ?? null;

            return (
                <ProductConfigurationPreviewTopBlock
                    configurationIdea={configurationIdea}
                    configurationIdeaHasBeenLoaded={props.availableConfigurationIdeasHaveBeenLoaded}
                    configurationPresentationSource={configurationPresentationSource}
                    cameraIdToShow={props.cameraIdToShow}
                    onSelected={presentationSource => handleProductConfigurationSelectionByPresentationSource(presentationSource)}
                />
            );
        });
    }


    return (
        <div className="product-configurations-preview">
            <div className="preview-columns-row">
                {getProductConfigurationsPreviewTopBlocks()}
            </div>

            <CollapsibleBlock title="Farbpalette" isCollapsedByDefault={true}>
                <div className="preview-columns-row">
                    {getInspirationColorsLists()}
                </div>
            </CollapsibleBlock>

            <CollapsibleBlock title="Template-Farben">
                <div className="preview-columns-row">
                    {getColorInputsLists()}
                </div>
            </CollapsibleBlock>
        </div>
    );
};


interface ProductConfigurationsPreviewProps
{
    configurationPresentationSources : ProductConfigurationPresentationSource[];
    availableConfigurationIdeas : ProductConfigurationIdea[];
    availableConfigurationIdeasHaveBeenLoaded : boolean;
    cameraIdToShow : string | null;
    templateSetTagSlots : TemplateTagKey[];
    defaultTemplateTags : TemplateTag[];
    onProductConfigurationSelected : (selectedIndex : number | null) => void | Promise<void>;
    onColorTemplateFieldsChange :  (updatedConfigurationPresentationSource : ProductConfigurationPresentationSource) => void | Promise<void>;
}


export default ProductConfigurationsPreview;
