import { FunctionComponent } from "react";
import "./mutability-toggle.css";


export const MutabilityToggle : FunctionComponent<MutabilityToggleProps> = props =>
{
    if(props.isOn)
    {
        return (
            <button
                className="mutability-toggle"
                disabled={!props.isEnabled}
                onClick={props.onToggle}
            >
                <img src="/images/icon-save.svg" alt="Speichern" />
            </button>
        );
    }
    else
    {
        return (
            <button
                className="mutability-toggle"
                disabled={!props.isEnabled}
                onClick={props.onToggle}
            >
                <img src="/images/icon-edit.svg" alt="Bearbeiten" />
            </button>
        );
    }
};


export interface MutabilityToggleProps
{
    isOn : boolean;
    isEnabled : boolean;
    onToggle() : void | Promise<void>;
}