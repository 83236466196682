import { Directions } from "../directions";
import "./navigation-button.css";


const NavigationButton = (props : NavigationButtonProps) =>
{
    return (
        <button
            id={props.id}
            className="navigation-button"
            onClick={props.onClick}
            disabled={props.disabled}
        >
            <span>{CONFIGURATION.DIRECTION_CONTENTS[props.direction]}</span>
        </button>
    );
}


interface NavigationButtonProps
{
    direction : Directions;
    id? : string;
    disabled? : boolean;
    onClick? : () => void | Promise<void>;
}


const CONFIGURATION =
{
    DIRECTION_CONTENTS:
    {
        [Directions.Up]:    '↑',
        [Directions.Down]:  '↓',
        [Directions.Left]:  '←',
        [Directions.Right]: '→',        
    }
};


export default NavigationButton;