export class Font
{
    private readonly typeFace : string;
    private readonly url : string;


    public constructor(typeFace : string, url : string)
    {
        this.typeFace = typeFace;
        this.url = url;
    }


    public get TypeFace() : string
    {
        return this.typeFace;
    }


    public get Url() : string
    {
        return this.url;
    }


    public get CssFontFaceRule() : string
    {
        return (
            `@font-face
            {
                font-family: "${this.typeFace}";
                src: url("${this.url}");
            }`
        );
    }
}