import React, { JSXElementConstructor, ReactElement, ReactFragment } from "react";
import { ProductConfigurationReference } from "../../api/product-configuration-reference";
import { ImageOption } from "../../image-option";
import { TextImageDropdown } from "../text-image-dropdown";
import { EditableLinearList } from "./editable-linear-list";
import { EditableBlock, EditableBlockProps } from "../editable-block";
import { ProductConfigurationListItemLabel } from "./product-configuration-definitions-list-item-label";
import { ProductConfigurationIdea } from "../../product-configuration-idea";


class ProductConfigurationDefinitionsList extends React.Component<ProductConfigurationDefinitionsListProps, State>
{
    public props : ProductConfigurationDefinitionsListProps;
    public state : State;


    public constructor(props : ProductConfigurationDefinitionsListProps)
    {
        super(props);
        this.props = props;

        this.state = { addInputValue: null };
    }


    private get AddInputOptions() : ImageOption[]
    {
        return this.props.availableConfigurationsIdeas.map(idea =>
        ({
            label: idea.Reference.Name || idea.Reference.DefinitionHash,
            value: idea.Reference.Name || idea.Reference.DefinitionHash,
            imageUrl: this.getLabelImageUrlForConfigurationIdea(idea)
        }));
    }


    private get Items() : ReactElement<EditableBlockProps, JSXElementConstructor<typeof EditableBlock>>[]
    {
        return this.props.configurationReferences.map((reference, itemIndex) =>
        {
            const labelText = reference.Name || reference.DefinitionHash;

            const configurationIdea = this.props.availableConfigurationsIdeas.find(idea => idea.checkIfReferenceMatches(reference));

            const labelImageUrl = configurationIdea && this.getLabelImageUrlForConfigurationIdea(configurationIdea);

            const label = (
                <ProductConfigurationListItemLabel
                    click={{
                        isPossible: true,
                        handle: () => this.props.onProductConfigurationSelection(itemIndex)
                    }}
                    text={labelText}
                    imageUrl={labelImageUrl ?? null}
                />
            );

            return (
                <li>
                    <EditableBlock
                        content={label}
                        removability={{ current: true }}
                        onRemovalClick={() => this.props.onEntryRemoval(reference)}
                        isBeingEdited={false}
                        key={itemIndex}
                    />
                </li>
            );
        });
    }


    private getLabelImageUrlForConfigurationIdea(configurationIdea : ProductConfigurationIdea) : string | null
    {
        let labelImage = null;

        const imageLabelsCameraId = this.props.imageLabelsCameraId;
        if(imageLabelsCameraId != null) labelImage = configurationIdea.Renderings.find(rendering => rendering.cameraId === imageLabelsCameraId) ?? null;

        const labelImageUrl = labelImage?.getBestPreferredUrl("original") ?? null;

        return labelImageUrl;
    }


    private get AdditionItem() : ReactFragment
    {
        return (
            <React.Fragment>
                <TextImageDropdown
                    disabled={!this.props.availableConfigurationsIdeasHaveBeenLoaded} 
                    id="product-configuration-definitions-list-add-input"
                    onInput={value => this.handleAdditionInputInput(value)}
                    onSubmit={() => this.handleAdditionInputSubmission()}
                    options={this.AddInputOptions}
                    value={this.state.addInputValue}
                />
            </React.Fragment>
        );
    }


    public render() : JSX.Element
    {
        return (
            <EditableLinearList
                items={this.Items}
                additionalClassName="product-configurations-list"
                listLabel="Produktkonfigurationen"
                additionItem={this.AdditionItem}
                isEnabled={this.props.availableConfigurationsIdeasHaveBeenLoaded}
            />
        );
    }


    private async handleAdditionInputSubmission()
    {
        const configurationNameOrDefinitionHash = this.state.addInputValue;
        if(!configurationNameOrDefinitionHash) return;

        const addedProductConfiguration = this.props.availableConfigurationsIdeas.find(idea =>
        {
            return (idea.Reference.Name === configurationNameOrDefinitionHash || idea.Reference.DefinitionHash === configurationNameOrDefinitionHash);
        });

        if(!addedProductConfiguration) return;

        const addedProductConfigurationDefinition = addedProductConfiguration.Reference;

        await this.props.onEntryAddition(addedProductConfigurationDefinition);
    }


    private async handleAdditionInputInput(value: string | null)
    {
        this.setState({ addInputValue: value });
    }
};


interface ProductConfigurationDefinitionsListProps
{
    availableConfigurationsIdeas : ProductConfigurationIdea[];
    availableConfigurationsIdeasHaveBeenLoaded : boolean;
    configurationReferences : ProductConfigurationReference[];
    imageLabelsCameraId : string | null;
    onEntryAddition : (addedProductConfiguration : ProductConfigurationReference) => void | Promise<void>;
    onEntryRemoval : (removedProductConfiguration : ProductConfigurationReference) => void | Promise<void>;
    onProductConfigurationSelection : (selectingIndex : number | null) => void | Promise<void>;
}


interface State
{
    addInputValue : string | null;
}


export default ProductConfigurationDefinitionsList;