import React from "react";
import { ImageOption } from "../image-option";
import { Utils } from "../utils";
import TextDropdown from "./text-dropdown";
import "./text-slots-list.css";


export class TextSlotsList extends React.Component
{
    public props : TextSlotsListProps;
    public state : State;


    public constructor(props : TextSlotsListProps)
    {
        super(props);
        this.props = props;

        this.state =
        {
            selectedValues: Array(this.props.slotsCount).fill(null)
        };
    }


    public render()
    {
        return (
            <fieldset className="slots-list">
                {this.LegendOrNothing}
                <ul>
                    {this.Slots}
                </ul>
            </fieldset>
        );
    }


    private async handleSelectionChange(updatedValue : string | null, changedSlotIndex : number)
    {
        const selectedValues = [];
        for(let slotIndex = 0; slotIndex < this.props.slotsCount; slotIndex++)
        {
            let slotIndexValue;

            if(slotIndex === changedSlotIndex) slotIndexValue = updatedValue;
            else slotIndexValue = this.props.selectedValues?.[slotIndex] || null;

            selectedValues[slotIndex] = slotIndexValue;
        }

        await this.props.onSelectionChange?.(selectedValues);

        this.setState({ selectedValues });
    }
    

    private getLabelOrNothing(text : string | null, htmlFor : string) : JSX.Element | null
    {
        if(text === null) return null;
        else return <label htmlFor={htmlFor}>{text}</label>;
    }


    private get Slots() : JSX.Element[]
    {
        const slots = [];

        for(let slotIndex = 0; slotIndex < this.props.slotsCount; slotIndex++)
        {
            let labelText = null;
            if(this.props.labels) labelText = '';
            if(this.props.labels?.[slotIndex]) labelText = this.props.labels[slotIndex];

            const selectId = `slots-list-select-${Utils.GenerateRandomUint()}`;

            const slotValue = this.props.selectedValues?.[slotIndex] ?? null;

            const slot = (
                <li key={slotIndex} className="slot">
                    {this.getLabelOrNothing(labelText, selectId)}
                    <TextDropdown
                        id={selectId}
                        value={slotValue}
                        options={this.props.options}
                        disabled={!!this.props.disabled}
                        onInput={() => {}}
                        onSubmit={value => this.handleSelectionChange(value, slotIndex)}
                    />
                </li>
            );
            slots.push(slot);
        }

        return slots;
    }


    private get LegendOrNothing() : JSX.Element | null
    {
        if(this.props.listLabel == null) return null;

        return <legend>{this.props.listLabel}</legend>;
    }
}


interface State
{
    selectedValues : (string | null)[];
}


export interface TextSlotsListProps
{
    slotsCount : number;
    options : ImageOption[];
    listLabel? : string;
    labels? : string[];
    selectedValues? : (string | null)[];
    defaultSelection? : ImageOption,
    disabled? : boolean;
    onSelectionChange? : (selectedValues : (string | null)[]) => void | Promise<void>
}