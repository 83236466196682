import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { ApiAdapter } from "./api-adapter";
import { Project } from "./project";
import { ServerApi } from "./server-api";
import { ProductConfigurationReference } from "./product-configuration-reference";
import { TemplateTagKey, TagType } from "../template-tag-key";
import { UnsavedProject } from "./unsaved-project";
import { LoginState } from "../login-state";
import { Font } from "../font";
import { ProductConfigurationIdea } from "../product-configuration-idea";
import { ProductConfigurationPresentationSource } from "../product-configuration-presentation-source";
import { TemplateTag } from "../template-tag";
import { TemplateTagFactory } from "../template-tag-factory";

export class ApiClient
{
    private backendClient : AxiosInstance;

    private backendOrigin : string;
    private frontendOrigin : string;


    public constructor()
    {
        this.backendOrigin = process?.env?.REACT_APP_BACKEND_ORIGIN ?? "http://localhost";
        this.frontendOrigin = process?.env?.REACT_APP_FRONTEND_ORIGIN ?? window.location.origin;

        const backendConfig : AxiosRequestConfig =
        {
            baseURL: new URL("/api", this.backendOrigin).href
        }

        this.backendClient = axios.create(backendConfig);
        this.backendClient.defaults.headers["HTTP_X_REQUESTED_WITH"] = "XMLHttpRequest";
        this.backendClient.defaults.withCredentials = true;
    }


    public buildTemplateDocumentUrl(projectId : number, productConfigurationSubpath : number, templateDocumentId : string) : string
    {
        return new URL(`/projects/${projectId}/${productConfigurationSubpath}/${templateDocumentId}`, this.frontendOrigin).href;
    }


    public async requestProjects() : Promise<Project[]>
    {
        const response = await this.backendClient.get("/projects");
        const backendProjects = (response.data as ServerApi.Project[]);
        const projects = ApiAdapter.ConvertBackendProjects(backendProjects);

        return projects;
    }


    public async requestProject(id : number) : Promise<Project>
    {
        const response = await this.backendClient.get(`/projects/${id}`);
        const backendProject = (response.data as ServerApi.Project);
        const project = ApiAdapter.ConvertBackendProject(backendProject);

        return project;
    }


    public async createProject(project : UnsavedProject) : Promise<number>
    {
        const backendProject = ApiAdapter.ConvertUnsavedProject(project);
        const response = await this.backendClient.post(`/projects`, backendProject);
        const { id } = response.data;

        return id;
    }


    public async updateProject(id : number, updatingProperties : Partial<UnsavedProject>) : Promise<void>
    {
        const updatingBackendProperties = ApiAdapter.ConvertProjectPatch(updatingProperties);
        
        await this.backendClient.patch(`/projects/${id}`, updatingBackendProperties);
    }


    public async removeProject(id : number) : Promise<void>
    {
        const requestBody = { project_id: id };

        await this.backendClient.delete(`/projects`, { data: requestBody });
    }


    public async requestFonts() : Promise<Font[]>
    {
        const response = await this.backendClient.get("/fonts");
        const backendFonts = response.data.fonts;
        const fonts = ApiAdapter.ConvertBackendAvailableFonts(backendFonts);

        return fonts;
    }


    public async requestTemplates() : Promise<string[]>
    {
        const response = await this.backendClient.get("/templates");
        const templates = response.data.templates;

        return templates;
    }


    public async requestTemplateSetTagKeys() : Promise<TemplateTagKey[]>
    {
        // mock

        return [
            { name: "background-color", tagType: TagType.MultiColor, subTagsCount: 4 },
            { name: "background-detail-1", tagType: TagType.Color, subTagsCount: 0 },
            { name: "background-detail-2", tagType: TagType.Color, subTagsCount: 0 },
            { name: "font-color", tagType: TagType.Color, subTagsCount: 0 },
            { name: "link-color", tagType: TagType.Color, subTagsCount: 0 },
            { name: "headings-font", tagType: TagType.Font, subTagsCount: 0 },
            { name: "running-text-font", tagType: TagType.Font, subTagsCount: 0 },
            { name: "buttons-font", tagType: TagType.Font, subTagsCount: 0 }
        ];
    }


    public async requestAllProductConfigurationIdeas() : Promise<ProductConfigurationIdea[]>
    {
        const response = await this.backendClient.get("/product-configuration-ideas");
        const backendIdeas : ServerApi.ProductConfigurationIdea[] = response.data.product_configuration_ideas;
        const ideas = ApiAdapter.ConvertBackendProductConfigurationIdeas(backendIdeas);

        return ideas;
    }


    public async requestProductConfigurationPresentationSource(projectId : number, configId : number) : Promise<ProductConfigurationPresentationSource>
    {
        const response = await this.backendClient.get(`/projects/${projectId}/${configId}`);
        const backendPresentationSource : ServerApi.ProductConfigurationPresentationSource = response.data.product_configuration_presentation_source;
        const presentationSource = ApiAdapter.ConvertBackendProductConfigurationPresentationSource(backendPresentationSource);

        return presentationSource;
    }


    public async updateProductConfiguration(projectId : number, configId : number, updatingProperties : Partial<ProductConfigurationPresentationSource>) : Promise<void>
    {
        const updatingBackendProperties = ApiAdapter.ConvertProductConfigurationPresentationSourcePatch(updatingProperties);

        await this.backendClient.patch(`/projects/${projectId}/${configId}`, updatingBackendProperties);
    }


    public async requestDefaultTemplateTags() : Promise<TemplateTag[]>
    {
        const response = await this.backendClient.get(`/default-template-fields`);
        const backendTemplateTags : ServerApi.TemplateField[] = response.data.default_template_fields;

        const templateTagFactory = new TemplateTagFactory();
        
        const templateTags = backendTemplateTags.map(tag => templateTagFactory.createTemplateTagFromBackend(tag));

        return templateTags;
    }


    // TO DO: This should be patched into /projects/:id instead of having a separate collection
    public async addProductConfigurationReferenceToProject(projectId : number, reference : ProductConfigurationReference) : Promise<number>
    {
        const requestBody =
        {
            project_id: projectId,
            reference: ApiAdapter.ConvertProductConfigurationReference(reference)
        };

        const response = await this.backendClient.post(`/product-configuration-presentation-sources`, requestBody);
        const subpath : number = response.data.subpath;

        return subpath;
    }


    public async removeProductConfigurationReferenceFromProject(projectId : number, referenceSubpath : number) : Promise<void>
    {
        const requestBody =
        {
            project_id: projectId,
            product_configuration_presentation_source_subpath: referenceSubpath
        };

        await this.backendClient.delete(`/product-configuration-presentation-sources`, { data: requestBody });
    }


    public async requestLoginState() : Promise<LoginState>
    {
        const response = await this.backendClient.get(`/login`);
        
        const loginState = ApiAdapter.ConvertLoginState(response.data);

        return loginState;
    }


    public async login(username : string, password : string) : Promise<boolean>
    {
        const requestBody = { username, password };

        const response = await this.backendClient.post(`/login`, requestBody);
        
        return (response.status >= 200 && response.status < 300);
    }


    public async clearDamSystemCache() : Promise<void>
    {
        await this.backendClient.delete(`/resourcespace-cache`);
    }
}