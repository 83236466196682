import Color from "color";
import ColorsListBackgroundItem from "./colors-list-background-item";
import ColorsListConfigurationItem from "./colors-list-configuration-item";
import "./colors-list.css";


export const ColorsList = (props : ColorsListProps) =>
{    
    let backgroundColorItem = null; 
    if(props.mainColor) backgroundColorItem = <ColorsListBackgroundItem color={props.mainColor} key="-1" />;
    
    const configurationColorItems = props.listColors.map((listColor, index) =>
    {
        return <ColorsListConfigurationItem color={listColor} key={index} />;
    });


    return (
        <ul className="colors-list">
            {backgroundColorItem}
            {configurationColorItems}
        </ul>
    );
}


export interface ColorsListProps
{
    mainColor : Color | null;
    listColors : Color[];
}