import { FunctionComponent } from "react";
import { ColorTextInput } from "./color-text-input";
import "./color-text-input-group.css";


const ColorTextInputGroup : FunctionComponent<ColorTextInputGroupProps> = props =>
{
    return (
        <div className="color-text-input-group">
            <label htmlFor={props.textInputId}>{props.label}</label>
            <ColorTextInput
                textInputId={props.textInputId}
                inputText={props.inputValue}
                onInput={props.onInput}
                onReceivedDrop={props.onReceivedDrop}
                onSubmit={props.onSubmit}
            />
        </div>
    );
}


export interface ColorTextInputGroupProps
{
    label : string;
    inputValue : string | null;
    textInputId : string;
    onSubmit() : void | Promise<void>;
    onInput(lowercaseValue: string) : void | Promise<void>;
    onReceivedDrop(lowercaseValue: string) : Promise<void>;
}


export default ColorTextInputGroup;