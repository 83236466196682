import { Rendering } from "../rendering";
import App from "./app";
import ProxyImage from "./proxy-image";
import "./style-comparison-preview.css";


const COMPONENT_CONFIGURATION = Object.freeze(
{
    RENDERING_SIZE_CODE: "original"
});


const StyleComparisonPreview = (props : StyleComparisonPreviewProps) =>
{
    let renderingOrMissingText = <span className="notice-text">Kein passendes Rendering gefunden.</span>;

    const renderingUrl = props.rendering?.getBestPreferredUrl(COMPONENT_CONFIGURATION.RENDERING_SIZE_CODE);

    if(renderingUrl)
    {
        const renderingDownloadUrl = props.rendering?.getUrl(App.IMAGE_OPENING_SIZE_CODE, [App.IMAGE_OPENING_FILE_EXTENSION]) ?? null;

        renderingOrMissingText = (
            <ProxyImage
                alt="Product Rendering"
                src={renderingUrl}
                clickDownloadUrl={renderingDownloadUrl}
            />
        );
    }

    let inspirationImageOrMissingText = <span className="notice-text">Kein Moodboard gefunden.</span>;
    if(props.inspirationImageUrl) inspirationImageOrMissingText = <img src={props.inspirationImageUrl} alt="Inspiration" />;

    return (
        <div className="style-comparison-preview">            
            <div className="image-container left-image-container">
                {renderingOrMissingText}
            </div>
            <div className="image-container right-image-container">
                {inspirationImageOrMissingText}
            </div>
        </div>
    );
}


interface StyleComparisonPreviewProps
{
    rendering : Rendering | null;
    inspirationImageUrl : string | null;
}


export default StyleComparisonPreview;