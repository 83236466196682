import "./loading-bar.css";


const failureText = "Laden fehlgeschlagen.";


const LoadingBar = (props : LoadingBarProps) =>
{
    let displayingText;
    if(props.loadingHasFailed)
    {
        displayingText = failureText;
    }
    else
    {
        const displayingNumber = Math.round(props.progressZeroToOne * 100);
        const displayingProgress = `${displayingNumber}%`;
        displayingText = displayingProgress;
    }

    return (
        <div className="loading-bar">
            <span>{displayingText}</span>
        </div>
    );
}


interface LoadingBarProps
{
    progressZeroToOne : number;
    loadingHasFailed : boolean;
}


export default LoadingBar;