import { FunctionComponent, ReactElement, ReactNode } from "react";
import "./editable-card.css";


export const EditableCard : FunctionComponent<EditableCardProps> = props =>
{
    function getWrappedRemovalButtonOrNothing() : JSX.Element | null
    {
        if(!props.removability) return null;

        return (
            <div>
                <button
                    className="removal-button"
                    disabled={!props.removability?.current}
                    onClick={props.onRemovalClick}
                >
                    <img src="/images/icon-delete.svg" alt="Entfernen" />
                </button>
            </div>
        );
    }


    function getWrappedMutabilityToggleOrNothing() : JSX.Element | null
    {
        if(!props.mutationElements?.mutabilityToggle) return null;

        return <div>{props.mutationElements?.mutabilityToggle}</div>;
    }


    function getContentOrEditInput() : ReactNode | null
    {
        if(props.isBeingEdited && props.mutationElements?.valueMutationInput) return props.mutationElements?.valueMutationInput;
        else return props.content;
    }


    return (
        <div className="editable-card">
            {getWrappedRemovalButtonOrNothing()}
            <div>{getContentOrEditInput()}</div>
            {getWrappedMutabilityToggleOrNothing()}
        </div>
    );
};


export interface EditableCardProps
{
    isBeingEdited : boolean;
    content : ReactNode;
    removability : { current : boolean } | null;
    mutationElements? : VariableListItem.MutationElements;
    onRemovalClick() : void | Promise<void>;
}


export namespace VariableListItem
{
    export interface MutationElements
    {
        valueMutationInput : ReactElement;
        mutabilityToggle? : ReactElement;
    }
}