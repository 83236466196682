import React from "react";
import "./button-link.css";


const ButtonLink = (props : ButtonLinkProps) : JSX.Element =>
{
    function getClassName()
    {
        const baseClassName = "button-link";

        if(props.disabled) return (`${baseClassName} disabled`);
        else return baseClassName;
    }


    function getTarget() : string
    {
        if(props.opensInNewTab) return "_blank";
        else return "";
    }


    function getHref() : string
    {
        const isUrl = (typeof(props.url) === "object");
        const url = isUrl ? (props.url as URL).href : (props.url as string);

        return url;
    }


    function handleClick(event : React.MouseEvent)
    {
        if(props.disabled) event.preventDefault();
    }


    return (
        <a
            className={getClassName()}
            href={getHref()}
            target={getTarget()}
            rel="noopener noreferrer"
            onClick={event => handleClick(event)}
        >
            {props.children}
        </a>
    );
};


interface ButtonLinkProps
{
    children : null | string | JSX.Element | JSX.Element[];
    url : string | URL;
    disabled? : boolean;
    opensInNewTab? : boolean;
}


export default ButtonLink;