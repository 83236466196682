export interface IEvent<T>
{
    addListener(handler : (data: T) => void) : void;

    addOnceListener(handler : (data: T) => void) : void;

    removeListener(handler : (data: T) => void) : void;
}


export class TypedEvent<T> implements IEvent<T>
{
    private handlers : ((data: T) => void)[] = [];

    private onceHandlers : ((data: T) => void)[] = [];


    public addListener(handler : (data: T) => void) : void
    {
        this.handlers.push(handler);
    }


    public addOnceListener(handler : (data: T) => void) : void
    {
        this.onceHandlers.push(handler);
    }


    public removeListener(handler : (data: T) => void) : void
    {
        this.handlers = this.handlers.filter(h => h !== handler);
    }


    public trigger(data: T)
    {
        this.handlers.slice(0).forEach(h => h(data));
        this.onceHandlers.slice(0).forEach(oh => oh(data));
        this.onceHandlers = [];
    }


    public expose() : IEvent<T>
    {
        return this;
    }
}