import { Link } from "react-router-dom";
import Page from "./page";
import "./home-page.css";


const HomePage = () =>
{
    return (
        <Page className="home-page">
            <nav className="start-menu">
                <ul>
                    <li>
                        <Link to="/projects">Projekte</Link>
                    </li>
                    <li>
                        <Link to="/search">Rendering-Suche</Link>
                    </li>
                </ul>
            </nav>
        </Page>
    );
};


export default HomePage;