import validator from "validator";

export class Utils
{
    public static Arrayize<T>(thing : T | T[]) : T[]
    {
        if(Array.isArray(thing)) return thing;
        else return [thing];
    }


    public static Capitalize(string : string) : string
    {
        return (string.charAt(0).toUpperCase() + string.slice(1));
    }


    public static CheckIfColorIsValid(hexColor : string | null) : boolean
    {
        if(hexColor === null) return false;

        return validator.isHexColor(hexColor);
    }


    public static GenerateRandomUint() : number
    {
        return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    }


    public static async WaitForMs(ms : number) : Promise<void>
    {
        return new Promise(resolve =>
        {
            setTimeout(resolve, ms);
        });
    }


    public static PrependHashsignIfMissing(text : string) : string
    {
        const hashsignIsMissing = (!text.startsWith('#'));
    
        if(hashsignIsMissing) return ('#' + text);
        else return text;
    }
}