import ColorTextInputGroup from "./color-text-input-group";
import React from "react";
import { NamedColor } from "../named-color";
import { FrameApi } from "../api/frame-api";
import FontsSlotsList from "./fonts-slots-list";
import Color from "color";
import Page from "./page";
import { TemplateTagKey } from "../template-tag-key";
import { Utils } from "../utils";
import { NamedString } from "../named-string";
import { ColorTextInput } from "./color-text-input";
import { TemplateFontTag } from "../template-font-tag";
import { Font } from "../font";


export class TemplateEditor extends React.Component<TemplateEditorProps, State>
{
    public state : State;

    private readonly textInputIdSuffix : string;
    private readonly frameApiSocket : FrameApi;
    private readonly handleReceivedColoursBound : (receivedColors: NamedColor[]) => void;
    private readonly handleReceivedFontsBound : (receivedFonts : TemplateFontTag[]) => void;


    public constructor(props : any)
    {
        super(props);

        this.state =
        {
            templateColorTexts: [],
            templateFonts: [],
            templateFontsHaveBeenReceived: false
        };

        this.textInputIdSuffix = ColorTextInput.GenerateInputId();

        this.frameApiSocket = new FrameApi();

        this.handleReceivedColoursBound = receivedColors => this.handleReceivedColors(receivedColors);
        this.frameApiSocket.ReceivedColors.addListener(this.handleReceivedColoursBound);

        this.handleReceivedFontsBound = receivedFonts => this.handleReceivedFonts(receivedFonts)
        this.frameApiSocket.ReceivedFonts.addListener(this.handleReceivedFontsBound);

        window.addEventListener("beforeunload", () => this.closeFrameApiSocket()); // componentWillUnmount is not working when window is actually closed
    }


    public componentDidMount()
    {
        this.frameApiSocket.sendReadyMessage();
    }


    public componentWillUnmount()
    {
        this.closeFrameApiSocket();
    }


    public render() : JSX.Element
    {
        return (
            <Page className="template-editor">
                <form onSubmit={event => event.preventDefault()}>

                    <fieldset>
                        <legend>
                            <span>Template-Farben</span>
                        </legend>
                        {this.TemplateColorElements}
                    </fieldset>

                    <FontsSlotsList
                        availableFonts={this.props.availableFonts}
                        disabled={!this.props.availableFontsHaveBeenLoaded || !this.state.templateFontsHaveBeenReceived}
                        occupiedFontTags={this.state.templateFonts}
                        onSelectionChange={selectedFonts => this.handleFontSelectionChange(selectedFonts)}
                        templateFontTagKeys={this.state.templateFonts.map(font => font.tagName ?? '')}
                    />

                </form>

                <div className="clear-cache-block">
                    <input type="button" value="DAM-System-Cache leeren" onClick={() => this.props.onClearCacheClick()} />
                </div>
            </Page>
        );
    }


    private get TemplateColors() : NamedColor[]
    {
        return this.state.templateColorTexts.map(colorText =>
        {            
            const colorIsValid = Utils.CheckIfColorIsValid(colorText.string);

            const color = colorIsValid ? new Color(colorText.string ?? '') : null;

            return {
                name: colorText.name,
                color
            };
        });
    }


    private get TemplateColorElements() : JSX.Element[]
    {
        return this.state.templateColorTexts.map((templateColorText, index) =>
        {
            return (
                <ColorTextInputGroup
                    textInputId={`${this.textInputIdSuffix}-${index}`}
                    inputValue={templateColorText.string}
                    label={templateColorText.name}
                    onInput={lowercaseValue => this.handleTemplateColorTextInputInput(lowercaseValue, index)}
                    onReceivedDrop={lowercaseValue => this.handleTemplateColorTextInputInput(lowercaseValue, index)}
                    onSubmit={() => this.handleColorInputSubmission(index)}
                    key={index}
                />
            );
        });
    }


    private async handleTemplateColorTextInputInput(lowercaseValue : string, colorInputIndex : number) : Promise<void>
    {
        const adaptedInputText = Utils.PrependHashsignIfMissing(lowercaseValue);

        const templateColorTexts = this.state.templateColorTexts;
        templateColorTexts[colorInputIndex].string = adaptedInputText;

        await new Promise(resolve => this.setState({ templateColorTexts }, resolve as () => void));

        this.frameApiSocket.sendSelectedColors(this.TemplateColors);
    }


    private async handleColorInputSubmission(colorInputIndex : number)
    {
        const templateColorTexts = this.state.templateColorTexts;
        const inputValue = templateColorTexts[colorInputIndex].string;
        
        const colorIsValid = Utils.CheckIfColorIsValid(inputValue);

        if(!colorIsValid)
        {
            templateColorTexts[colorInputIndex].string = '';
            this.setState({ templateColorTexts });
        }
    }


    private handleReceivedColors(receivedColors : NamedColor[])
    {
        const currentColors = this.TemplateColors;

        for(const receivedColor of receivedColors)
        {
            let existingColorWithName = currentColors.find(color => color.name === receivedColor.name);

            if(existingColorWithName) existingColorWithName.color = receivedColor.color;
            else currentColors.push(receivedColor);
        }

        const templateColorTexts : NamedString[] = currentColors.map(currentColor =>
        ({
            name: currentColor.name,
            string: currentColor.color?.hex() ?? ''
        }));

        this.setState({ templateColorTexts });
    }


    private handleReceivedFonts(receivedFonts : TemplateFontTag[])
    {
        const untouchedFonts = this.state.templateFonts.filter(currentFont => !receivedFonts.some(receivedFont => receivedFont.tagName === currentFont.tagName));

        const templateFonts = [...untouchedFonts, ...receivedFonts];

        this.setState({ templateFonts, templateFontsHaveBeenReceived: true });
    }


    private handleFontSelectionChange(selectedFonts: TemplateFontTag[])
    {
        this.frameApiSocket.sendSelectedFonts(selectedFonts);
        
        this.setState({ templateFonts: selectedFonts });
    }


    private closeFrameApiSocket()
    {        
        this.frameApiSocket.sendCloseMessage();
        this.frameApiSocket.ReceivedColors.removeListener(this.handleReceivedColoursBound);
        this.frameApiSocket.ReceivedFonts.removeListener(this.handleReceivedFontsBound);
    }
}


interface State
{
    templateColorTexts : NamedString[];
    templateFonts : TemplateFontTag[];
    templateFontsHaveBeenReceived : boolean;
}


interface TemplateEditorProps
{    
    availableFonts : Font[];
    availableFontsHaveBeenLoaded : boolean;
    templateSetTagKeys : TemplateTagKey[];
    onClearCacheClick() : Promise<void>;
}


export default TemplateEditor;