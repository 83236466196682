import Color from "color";
import React from "react";
import { Utils } from "../utils";
import { ColorTextInput } from "./color-text-input";
import "./hierarchical-colors-input-group.css";


export class HierarchicalColorsInputGroup extends React.Component<HierarchicalColorsInputGroupProps, State>
{
    public props : HierarchicalColorsInputGroupProps;
    public state : State;

    private readonly textInputId : string;


    public constructor(props : HierarchicalColorsInputGroupProps)
    {
        super(props);
        this.props = props;

        this.state =
        {
            inputValues: this.props.colors.map(color => color?.hex() ?? null),
            draggingInputIndex: null
        };

        this.textInputId = ColorTextInput.GenerateInputId();
    }


    public render() : JSX.Element
    {
        return (
            <div className="hierarchical-colors-input-group">
                <label>{this.props.label}</label>
                {this.ColorInputRows}
            </div>
        );
    }


    private get ColorInputRows() : JSX.Element[]
    {
        return this.state.inputValues.map((inputValue, index) =>
        {
            return (
                <div className="color-input-row">
                    <ColorTextInput
                        textInputId={this.textInputId}
                        inputText={inputValue}
                        onInput={lowercaseValue => this.handleInputInput(lowercaseValue, index)}
                        onDragStart={() => this.setState({ draggingInputIndex: index })}
                        onDragEnd={() => this.setState({ draggingInputIndex: null })}
                        onReceivedDrop={lowercaseValue => this.handleInputReceivedDrop(lowercaseValue, index)}
                        onSubmit={async () => await this.submitColors()}
                        key={index}
                    />
                </div>
            );
        });
    }


    private async handleInputInput(lowercaseValue : string, colorIndex : number)
    {
        const adaptedInputText = Utils.PrependHashsignIfMissing(lowercaseValue);

        const inputValues = this.state.inputValues;
        inputValues[colorIndex] = adaptedInputText;

        this.setState({ inputValues });
    }


    private async handleInputReceivedDrop(lowercaseValue : string, colorIndex : number)
    {
        const adaptedInputText = Utils.PrependHashsignIfMissing(lowercaseValue);

        let inputHexColor = null;
        const colorIsValid = Utils.CheckIfColorIsValid(adaptedInputText);
        if(colorIsValid) inputHexColor = adaptedInputText;

        const inputValues = this.state.inputValues;

        const dragSourceIsInGroup = (this.state.draggingInputIndex !== null);
        if(dragSourceIsInGroup) inputValues[this.state.draggingInputIndex as number] = inputValues[colorIndex];
        inputValues[colorIndex] = inputHexColor;

        this.setState({ inputValues });
    }


    private async submitColors()
    {
        const inputValues = this.state.inputValues;

        const inputColors = inputValues.map((inputValue, index) =>
        {
            const colorIsValid = Utils.CheckIfColorIsValid(inputValue);
    
            const inputColor = colorIsValid ? new Color(inputValue ?? '') : null;
            if(!inputColor)
            {
                const inputValues = this.state.inputValues;
                inputValues[index] = null;
                this.setState({ inputValues });
            }

            return inputColor;
        });
        
        await this.props.onSubmit(inputColors);
    }
};


interface HierarchicalColorsInputGroupProps
{
    colors : (Color | null)[];
    label : string;
    onSubmit(updatedColors : (Color | null)[]) : void | Promise<void>
}


interface State
{
    inputValues : (string | null)[];
    draggingInputIndex : number | null;
}


export default HierarchicalColorsInputGroup;