import { ProductConfigurationReference } from "./api/product-configuration-reference";
import { TemplateTag } from "./template-tag";


export class ProductConfigurationPresentationSource
{
    private readonly reference : ProductConfigurationReference;

    private templateFields : TemplateTag[];


    public constructor(reference : ProductConfigurationReference, templateFields : TemplateTag[])
    {
        this.reference = reference;
        this.templateFields = templateFields;
    }


    public get Reference() : ProductConfigurationReference
    {
        return this.reference;
    }


    public get TemplateFields() : TemplateTag[]
    {
        return this.templateFields;
    }


    public set TemplateFields(value : TemplateTag[])
    {
        this.templateFields = value;
    }


    public get ReferenceNameOrSkeletonId() : string
    {
        return this.reference.NameOrSkeletonId;
    }


    public filterTemplateFields(fieldNames : string[])
    {
        this.templateFields = this.templateFields.filter(field => fieldNames.includes(field.TagKey));
    }
}