import "./product-detail-preview.css";
import HtmlOptionContent from "../html-option-content";
import App from "./app";
import ButtonLink from "./button-link";
import CopyToClipboardButton from "./copy-to-clipboard-button";
import ProxyImage from "./proxy-image";
import WebViewerButtonLink from "./webviewer-button-link";
import { ProductConfigurationIdea } from "../product-configuration-idea";


const COMPONENT_CONFIGURATION =
{
    IMAGES_SIZE_CODE: "original"
};


const ProductDetailPreview = (props : ProductDetailPreviewProps) =>
{
    function getRenderingImagesOrMissingNotices(cameraIdsToShow : (string | null)[]) : JSX.Element[]
    {
        const renderingImagesOrMissingNotices = cameraIdsToShow.map((cameraId, index) => 
        {
            if(cameraId == null)
            {
                return (
                    <div className="missing-motice-container">
                        <span>Keine Kamera gewählt.</span>
                    </div>
                );
            }

            const rendering = props.configurationIdea.getRendering(cameraId);
            const renderingUrl = rendering?.getBestPreferredUrl(COMPONENT_CONFIGURATION.IMAGES_SIZE_CODE);
            
            if(renderingUrl == null) return <span className="missing-motice-container">Kein passendes Rendering gefunden.</span>;
            else
            {
                const renderingDownloadUrl = rendering?.getUrl(App.IMAGE_OPENING_SIZE_CODE, [App.IMAGE_OPENING_FILE_EXTENSION]) ?? null;

                return <ProxyImage
                    key={index}
                    alt={`Rendering ${cameraId}`}
                    src={renderingUrl}
                    clickDownloadUrl={renderingDownloadUrl}
                />;
            }
        });

        return renderingImagesOrMissingNotices;
    }


    function getTemplateContentLinks() : JSX.Element[]
    {
        return props.linkTextsAndUrls.map(({ templateName, url }, index) =>
        {
            return <ButtonLink key={index} url={url} opensInNewTab>{templateName}</ButtonLink>;
        });
    }


    function handleOpenAllPiecesOfContentClick()
    {
        const allPiecesOfContentLinks = Array.from(document.querySelectorAll(".product-detail-preview .content-buttons a"));
        const allPiecesOfContentUrls = allPiecesOfContentLinks.map(link => link.getAttribute("href")).filter(url => !!url) as string[];

        allPiecesOfContentUrls.forEach(url =>
        {
            window.open(url, "_blank");
        });
    }


    return (
        <div className="product-detail-preview">
            <div className="buttons-row">
                <CopyToClipboardButton stringToCopy={props.configurationIdea.Reference.Definition}>Definition kopieren</CopyToClipboardButton>
                <WebViewerButtonLink productConfigurationDefinitionString={props.configurationIdea.Reference.Definition} />
            </div>
            <span className="name">{props.configurationIdea.ReferenceNameOrSkeletonId}</span>
            <div className="product-visuals">
                <div className="product-perspectives">
                    {getRenderingImagesOrMissingNotices(props.cameraIdsToShow)}
                </div>
            </div>
            <div className="buttons-row content-buttons">
                {getTemplateContentLinks()}
                {props.linkTextsAndUrls.length > 0 ? <button onClick={handleOpenAllPiecesOfContentClick}>Alle in Tabs</button> : null}
            </div>
        </div>
    );
};


interface ProductDetailPreviewProps
{
    configurationIdea : ProductConfigurationIdea;
    cameraIdsToShow : (string | null)[];
    linkTextsAndUrls : { templateName : string, url : string }[];
}


export default ProductDetailPreview;