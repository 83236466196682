import "./routeable-project-view.css";
import { useHistory, useParams } from "react-router";
import { ProductConfigurationReference } from "../api/product-configuration-reference";
import { Project } from "../api/project";
import { Font } from "../font";
import { ProductConfigurationIdea } from "../product-configuration-idea";
import { ProductConfigurationPresentationSource } from "../product-configuration-presentation-source";
import { TemplateTag } from "../template-tag";
import { TemplateTagKey } from "../template-tag-key";
import FourOhFourPage from "./four-oh-four-page";
import ProjectView from "./project-view";

const RouteableProjectView = (props : RouteableProjectViewProps) =>
{
    function getProject(id : number) : Project
    {
        const project = props.availableProjects.find(project => project.Id === id);
        if(!project) throw new Error("Project not found.");

        return project;
    }


    async function handleProjectViewLeave()
    {
        await props.onProjectViewLeave();

        history.push("/projects");
    }


    const history = useHistory();
    const { id } = useParams<{id : string}>();

    try
    {
        if(!props.availableProjectsHaveBeenLoaded) return <span className="routeable-project-view loading-indicator">Lade globale Informationen ...</span>;

        const idNumber = parseInt(id);
        if(isNaN(idNumber)) throw new Error("Invalid project ID parameter.");

        const project = getProject(idNumber);

        return (
            <ProjectView
                project={project}
                selectedConfigurationIndex={props.selectedConfigurationIndex}
                availableConfigurationIdeasHaveFailed={props.availableConfigurationIdeasHaveFailed}
                configurationPresentationSourcesHaveFailed={props.configurationPresentationSourcesHaveFailed}
                defaultTemplateTags={props.defaultTemplateTags}
                defaultTemplateTagsHaveBeenLoaded={props.defaultTemplateTagsHaveBeenLoaded}
                onProductConfigurationReferenceAddition={props.onProductConfigurationReferenceAddition}
                onProductConfigurationReferenceRemoval={props.onProductConfigurationReferenceRemoval}
                onProjectUpdate={props.onProjectUpdate}
                availableDocumentTemplateIds={props.availableDocumentTemplateIds}
                availableDocumentTemplateIdHaveBeenLoaded={props.availableDocumentTemplateIdHaveBeenLoaded}
                availableFonts={props.availableFonts}
                availableFontsHaveBeenLoaded={props.availableFontsHaveBeenLoaded}
                configurationPresentationSources={props.configurationPresentationSourcesPerProject.get(project) ?? []}
                configurationPresentationSourcesHaveBeenLoaded={props.configurationPresentationSourcesPerProjectHaveBeenLoaded}
                availableConfigurationIdeas={props.availableConfigurationIdeas}
                availableConfigurationIdeasHaveBeenLoaded={props.availableConfigurationIdeasHaveBeenLoaded}
                templateSetTagKeys={props.templateSetTagKeys}
                onClearCacheClick={props.onClearCacheClick}
                onProductConfigurationSelection={props.onProductConfigurationSelection}
                onLeave={handleProjectViewLeave}
            />
        );
    }
    catch(error)
    {
        return <FourOhFourPage />;
    }
};


interface RouteableProjectViewProps
{
    availableProjects : Project[];
    availableProjectsHaveBeenLoaded : boolean;
    configurationPresentationSourcesPerProject : Map<Project, ProductConfigurationPresentationSource[]>;
    configurationPresentationSourcesPerProjectHaveBeenLoaded : boolean;
    configurationPresentationSourcesHaveFailed : boolean;
    availableConfigurationIdeas : ProductConfigurationIdea[];
    availableConfigurationIdeasHaveBeenLoaded : boolean;
    availableConfigurationIdeasHaveFailed : boolean;
    availableDocumentTemplateIds : string[];
    availableDocumentTemplateIdHaveBeenLoaded : boolean;
    availableFonts : Font[];
    availableFontsHaveBeenLoaded : boolean;
    templateSetTagKeys : TemplateTagKey[];
    defaultTemplateTags : TemplateTag[];
    defaultTemplateTagsHaveBeenLoaded : boolean;
    selectedConfigurationIndex : number | null;
    onClearCacheClick(): void | Promise<void>;
    onProductConfigurationReferenceAddition(changingProject : Project, addingReference : ProductConfigurationReference) : void | Promise<void>;
    onProductConfigurationReferenceRemoval(changingProject : Project, removingReference : ProductConfigurationReference) : void | Promise<void>;
    onProductConfigurationSelection(selectingIndex : number | null) : void | Promise<void>;
    onProjectUpdate(): void | Promise<void>;
    onProjectViewLeave() : void | Promise<void>;
}


export default RouteableProjectView;