import { FunctionComponent, ImgHTMLAttributes } from "react";

// only alters extension when URL has a file extension
const ProxyImage : FunctionComponent<ImgHTMLAttributes<HTMLImageElement> & ProxyImageProps> = props =>
{
    const RIGHT_BUTTON_NUMBER = 2;


    function handleClick(event : React.MouseEvent<HTMLImageElement>)
    {
        if(event.button !== RIGHT_BUTTON_NUMBER || event.ctrlKey) return;        

        const downloadUrl = props.clickDownloadUrl;
        if(downloadUrl) openInNewTab(downloadUrl ?? props.src);
    }


    function openInNewTab(url : string)
    {
        window.open(url, "_blank", "noopener,noreferrer");
        window.focus();
    }


    return (
        <img
            {...props}
            alt={props.alt} // actually not necessary but avoids linter warning
            onMouseDown={handleClick}
        />
    );
};


interface ProxyImageProps
{
    clickDownloadUrl : string | null;
}


export default ProxyImage;