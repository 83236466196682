import "./project-view.css";
import React from "react";
import { Directions } from "../directions";
import NavigationButton from "./navigation-button";
import Page from "./page";
import { Project } from "../api/project";
import ProductConfigurationsPreview from "./product-configurations-preview";
import ProductDetailPreview from "./product-detail-preview";
import { TextSlotsList } from "./text-slots-list";
import { ApiClient } from "../api/api-client";
import { ProductConfigurationReference } from "../api/product-configuration-reference";
import StyleComparisonPreview from "./style-comparison-preview";
import LoadingBar from "./loading-bar";
import { TagType, TemplateTagKey } from "../template-tag-key";
import FontsSlotsList from "./fonts-slots-list";
import { UnsavedProject } from "../api/unsaved-project";
import { ImageOption } from "../image-option";
import { RichSlotsList as TextImageSlotsList } from "./text-image-slots-list";
import { CollapsibleBlock } from "./collapsible-block";
import { Rendering } from "../rendering";
import { TemplateFontTag } from "../template-font-tag";
import { Font } from "../font";
import Gallery from "./gallery";
import ProductConfigurationDefinitionsList from "./lists/product-configuration-definitions-list";
import { ProductConfigurationPresentationSource } from "../product-configuration-presentation-source";
import { ProductConfigurationIdea } from "../product-configuration-idea";
import { TemplateTag } from "../template-tag";


class ProjectView extends React.Component<ProjectViewProps>
{
    public props : ProjectViewProps;

    private apiClient : ApiClient;


    public constructor(props : ProjectViewProps)
    {
        super(props);
        this.props = props;

        this.apiClient = new ApiClient();

        document.body.addEventListener("dragend", event => console.log(event));
    }


    private get ExistingCameraIds() : string[]
    {
        const { configurationPresentationSources, availableConfigurationIdeas } = this.props;

        const configurationIdeas = configurationPresentationSources.map(presentationSource =>
        {
            return availableConfigurationIdeas.find(idea => idea.checkIfReferenceMatches(presentationSource.Reference));

        }).filter(idea => !!idea) as ProductConfigurationIdea[];
        
        const nonUniqueExistingCameraIds = configurationIdeas.flatMap(idea => idea.CameraIds);
        const uniqueExistingCameraIds = Array.from(new Set(nonUniqueExistingCameraIds));
        const existingCameraIds = uniqueExistingCameraIds.sort();

        return existingCameraIds;
    }


    private get ShowingConfigurationPresentationSource() : ProductConfigurationPresentationSource | null
    {
        if(this.props.selectedConfigurationIndex == null)  return null;

        return this.props.configurationPresentationSources[this.props.selectedConfigurationIndex] ?? null;
    }


    private get ShowingConfigurationIdea() : ProductConfigurationIdea | null
    {
        const showingProjectConfigurationPresentationSource = this.ShowingConfigurationPresentationSource;

        let showingPresentationSourceConfigurationIdea : ProductConfigurationIdea | null = null;
        if(showingProjectConfigurationPresentationSource) showingPresentationSourceConfigurationIdea = this.props.availableConfigurationIdeas.find(idea => 
        {
            return !!showingProjectConfigurationPresentationSource && idea.checkIfReferenceMatches(showingProjectConfigurationPresentationSource.Reference);
        }) ?? null;

        return showingPresentationSourceConfigurationIdea;
    }


    private get StyleComparisonPreview() : JSX.Element
    {
        let showingConfigurationIdea = this.ShowingConfigurationIdea;
        if(!showingConfigurationIdea) throw new Error("No product configuration available for rendering.");

        const firstRendering = showingConfigurationIdea && this.getFirstRendering(showingConfigurationIdea);

        const inspirationImageUrl = showingConfigurationIdea?.Inspiration?.imageUrl ?? null;

        return <StyleComparisonPreview
            rendering={firstRendering}
            inspirationImageUrl={inspirationImageUrl}
        />
    }


    private getFirstRendering(configurationIdea : ProductConfigurationIdea) : Rendering | null
    {
        const cameraId = this.props.project.MainCameraId;
        if(!cameraId) return null;

        const rendering = configurationIdea.Renderings.find(rendering => rendering.cameraId === cameraId);

        return rendering ?? null;
    }


    private async handleCamerasSelectionChange(selectedValues : (string | null)[])
    {
        const showingProject = this.props.project;
        showingProject.CameraIds = selectedValues;

        const projectPatch : Partial<UnsavedProject> = { CameraIds: showingProject.CameraIds };
        await this.apiClient.updateProject(showingProject.Id, projectPatch);

        await this.props.onProjectUpdate();
    }


    private async handleFontsSelectionChange(selectedFonts : TemplateFontTag[])
    {
        const showingProject = this.props.project;
        showingProject.Fonts = selectedFonts;

        const projectPatch : Partial<UnsavedProject> = { Fonts: showingProject.Fonts };
        await this.apiClient.updateProject(showingProject.Id, projectPatch);

        await this.props.onProjectUpdate();
    }


    private async handleTemplateDocumentsSelectionChange(selectedValues : (string | null)[])
    {
        const showingProject = this.props.project;
        showingProject.TemplateDocumentIds = selectedValues;

        const projectPatch : Partial<UnsavedProject> = { TemplateDocumentIds: showingProject.TemplateDocumentIds };
        await this.apiClient.updateProject(showingProject.Id, projectPatch);

        await this.props.onProjectUpdate();
    }


    private async handleNavigationButtonClick()
    {
        await this.props.onLeave();
    }


    private async handleProductConfigurationDefinitionAddition(addedConfigurationReference : ProductConfigurationReference)
    {
        await this.props.onProductConfigurationReferenceAddition(this.props.project, addedConfigurationReference);
    }


    private async handleProductConfigurationDefinitionRemoval(removingConfigurationReference : ProductConfigurationReference)
    {
        const userWantsToDelete = window.confirm(`Soll die Produktkonfiguration "${removingConfigurationReference.NameOrDefinitionHash}" wirklich gelöscht werden?`);
        if(!userWantsToDelete) return;

        await this.props.onProductConfigurationReferenceRemoval(this.props.project, removingConfigurationReference);
    }


    private async handleColorTemplateFieldsChange(updatedPresentationSource: ProductConfigurationPresentationSource) : Promise<void>
    {
        const projectId = this.props.project.Id;
        const productConfigurationIndex = this.props.configurationPresentationSources.findIndex(source => source === updatedPresentationSource);
        const productConfigurationSubpath = this.props.project.ProductConfigurationReferenceSubpaths[productConfigurationIndex];

        await this.apiClient.updateProductConfiguration(projectId, productConfigurationSubpath, updatedPresentationSource);

        await this.props.onProjectUpdate();
    }


    private get TemplateDocumentOptions() : ImageOption[]
    {
        return this.props.availableDocumentTemplateIds.map(subpath =>
        ({
            label: subpath,
            value: subpath,
            imageUrl: null
        }));
    }


    private get SelectedProductConfigurationSubpath() : number
    {
        const selectedPresentationSource = this.ShowingConfigurationPresentationSource;
        const selectedPresentationSourceIndex = this.props.configurationPresentationSources.findIndex(source => source === selectedPresentationSource);
        if(selectedPresentationSourceIndex === -1) throw new Error("Something went terribly wrong.");

        const selectedConfigurationSubpath = this.props.project.getProductConfigurationReferenceSubpathAt(selectedPresentationSourceIndex);

        return selectedConfigurationSubpath;
    }


    private get SelectedTemplateUrlsAndNames() : { templateName : string; url : string; }[]
    {
        const setTemplateDocumentIds = this.props.project.NonNullTemplateDocumentIds;

        const selectedProductConfigurationSubpath = this.SelectedProductConfigurationSubpath;

        const projectId = this.props.project.Id;

        return setTemplateDocumentIds.map(templateDocumentId =>
        ({
            templateName: templateDocumentId,
            url: this.apiClient.buildTemplateDocumentUrl(projectId, selectedProductConfigurationSubpath, templateDocumentId)
        }));
    }


    private get ProductConfigurationsPreviewOrLoadIndicator() : JSX.Element
    {
        const loadPercentage = this.props.configurationPresentationSourcesHaveBeenLoaded ? 1 : 0;

        if(loadPercentage < 1) return <LoadingBar progressZeroToOne={loadPercentage} loadingHasFailed={this.props.configurationPresentationSourcesHaveFailed} />
        else
        {
            return (
                <ProductConfigurationsPreview
                    availableConfigurationIdeas={this.props.availableConfigurationIdeas}
                    availableConfigurationIdeasHaveBeenLoaded={this.props.availableConfigurationIdeasHaveBeenLoaded}
                    configurationPresentationSources={this.props.configurationPresentationSources}
                    defaultTemplateTags={this.props.defaultTemplateTags}
                    cameraIdToShow={this.props.project.MainCameraId ?? null}
                    templateSetTagSlots={this.props.templateSetTagKeys}
                    onProductConfigurationSelected={selectedIndex => this.props.onProductConfigurationSelection(selectedIndex)}
                    onColorTemplateFieldsChange={updatedProductConfiguration => this.handleColorTemplateFieldsChange(updatedProductConfiguration)}
                />
            );
        }
    }


    private get CameraDropdownOptions() : ImageOption[]
    {
        return this.ExistingCameraIds.map(cameraId =>
        {
            const showingConfigurationIdea = this.ShowingConfigurationIdea;
            
            const productConfigurationCameraRendering = showingConfigurationIdea?.Renderings.find(rendering => rendering.cameraId === cameraId);
            
            const cameraRenderingUrl = productConfigurationCameraRendering?.getBestPreferredUrl("original") ?? null;

            const imageOption : ImageOption =
            {
                value: cameraId,
                label: cameraId,
                imageUrl: cameraRenderingUrl
            };
            
            return imageOption;
        });
    }


    public render() : JSX.Element
    {
        return (
            <Page className="project-view">
                <div className="navigation-button-container">
                    <NavigationButton direction={Directions.Up} onClick={() => this.handleNavigationButtonClick()} />
                </div>
                
                <h1>{this.props.project.Name}</h1>
    
                <CollapsibleBlock title="Projekteinstellungen" isCollapsedByDefault={true}>
                    <div className="top-block">
                        <div className="product-configurations-block">
                            <ProductConfigurationDefinitionsList
                                availableConfigurationsIdeas={this.props.availableConfigurationIdeas}
                                availableConfigurationsIdeasHaveBeenLoaded={this.props.availableConfigurationIdeasHaveBeenLoaded}
                                configurationReferences={this.props.configurationPresentationSources.map(source => source.Reference)}
                                imageLabelsCameraId={this.props.project.MainCameraId}
                                onEntryAddition={configReference => this.handleProductConfigurationDefinitionAddition(configReference)}
                                onEntryRemoval={configReference => this.handleProductConfigurationDefinitionRemoval(configReference)}
                                onProductConfigurationSelection={selectingIndex => this.props.onProductConfigurationSelection(selectingIndex)}
                            />
                        </div>
                        <div>
                            <form>
                                <label>
                                    <span>Moodboard Projektname:</span>
                                    <input type="url" />
                                </label>
                            </form>
                            <p>Context Pinwand</p>
                        </div>
                        <div>
                            <FontsSlotsList
                                availableFonts={this.props.availableFonts}
                                occupiedFontTags={this.props.project.Fonts}
                                templateFontTagKeys={this.props.templateSetTagKeys.filter(tagKey => tagKey.tagType === TagType.Font)?.map(tagKey => tagKey.name) ?? []}
                                disabled={!this.props.availableFontsHaveBeenLoaded}
                                onSelectionChange={selectedFonts => this.handleFontsSelectionChange(selectedFonts)}
                            />
                        </div>
                        <div>
                            <TextSlotsList
                                disabled={!this.props.availableDocumentTemplateIdHaveBeenLoaded}
                                listLabel="Generierter Content"
                                selectedValues={this.props.project.TemplateDocumentIds}
                                options={this.TemplateDocumentOptions}
                                slotsCount={4}
                                onSelectionChange={selectedValues => this.handleTemplateDocumentsSelectionChange(selectedValues)}
                            />
                        </div>
                        <div>
                            <TextImageSlotsList
                                disabled={!this.props.availableConfigurationIdeasHaveBeenLoaded || !this.props.configurationPresentationSourcesHaveBeenLoaded}
                                listLabel="Kameras"
                                selectedValues={this.props.project.CameraIds}
                                options={this.CameraDropdownOptions}
                                slotsCount={4}
                                onSelectionChange={selectedValues => this.handleCamerasSelectionChange(selectedValues)}
                            />
                        </div>
                    </div>
                </CollapsibleBlock>
    
                <CollapsibleBlock title="Produktkonfigurationen">
                    {this.ProductConfigurationsPreviewOrLoadIndicator}
                </CollapsibleBlock>

                <CollapsibleBlock title="Moodboard" isCollapsedByDefault={true}>
                    {this.ShowingConfigurationIdea ? this.StyleComparisonPreview : null}
                </CollapsibleBlock>

                <CollapsibleBlock title="Hauptansichten">
                    {this.ShowingConfigurationIdea ? <ProductDetailPreview configurationIdea={this.ShowingConfigurationIdea} cameraIdsToShow={this.props.project.CameraIds} linkTextsAndUrls={this.SelectedTemplateUrlsAndNames} /> : null}
                </CollapsibleBlock>

                <CollapsibleBlock title="Alle Ansichten" isCollapsedByDefault={true}>
                    {this.ShowingConfigurationPresentationSource ? <Gallery configurationIdea={this.ShowingConfigurationIdea} /> : null}
                </CollapsibleBlock>

                <div className="clear-cache-block">
                    <input type="button" value="DAM-System-Cache leeren" onClick={this.props.onClearCacheClick} />
                </div>
    
            </Page>
        );
    }
}


interface ProjectViewProps
{
    project : Project;
    selectedConfigurationIndex : number | null;
    availableConfigurationIdeas : ProductConfigurationIdea[];
    availableConfigurationIdeasHaveBeenLoaded : boolean;
    availableConfigurationIdeasHaveFailed : boolean;
    configurationPresentationSources : ProductConfigurationPresentationSource[];
    configurationPresentationSourcesHaveBeenLoaded : boolean;
    configurationPresentationSourcesHaveFailed : boolean;
    availableDocumentTemplateIds : string[];
    availableDocumentTemplateIdHaveBeenLoaded : boolean;
    availableFonts : Font[];
    availableFontsHaveBeenLoaded : boolean;
    defaultTemplateTags : TemplateTag[];
    defaultTemplateTagsHaveBeenLoaded : boolean;
    templateSetTagKeys : TemplateTagKey[];
    onClearCacheClick(): void | Promise<void>;
    onLeave: () => void | Promise<void>;
    onProductConfigurationReferenceAddition(changingProject : Project, addingReference : ProductConfigurationReference) : void | Promise<void>;
    onProductConfigurationReferenceRemoval(changingProject : Project, removingReference : ProductConfigurationReference) : void | Promise<void>;
    onProductConfigurationSelection(selectingIndex : number | null) : void | Promise<void>;
    onProjectUpdate(): void | Promise<void>;
}


export default ProjectView;