import { ServerApi } from "./api/server-api";
import { TemplateTagFactory } from "./template-tag-factory";
import { TemplateTagKey } from "./template-tag-key";

export abstract class TemplateTag
{
    private readonly tagKey : string;


    public abstract get TagType() : string;


    public get TagKey() : string
    {
        return this.tagKey;
    }


    public abstract get SerializedValue() : string;


    public abstract getManipulationComponent(onSubmit : () => void | Promise<void>) : JSX.Element | null;


    public get HasInitializedValue() : boolean
    {
        return (this.SerializedValue !== JSON.stringify(null));
    }


    public typeEquals(other : TemplateTag) : boolean
    {
        return (this.TagType === other.TagType);
    }


    public referenceEqualsTag(other: TemplateTag) : boolean
    {
        return (this.typeEquals(other) && this.tagKey === other.tagKey);
    }


    public referenceEqualsSlot(tagSlot : TemplateTagKey) : boolean
    {
        let isMatching = true;

        isMatching = isMatching && (tagSlot.name === this.TagKey);
        isMatching = isMatching && (tagSlot.tagType === TemplateTagFactory.ConvertStringToTagType(this.TagType));

        return isMatching;
    }


    public fillWith(valueSourceTag : TemplateTag)
    {
        if(!this.typeEquals(valueSourceTag)) throw new Error("Tried to fill a template tag with content of differently typed tag.");
    }


    public toBackendTemplateField() : ServerApi.TemplateField
    {
        return {
            tag_type: this.TagType,
            template_tag_name: this.TagKey,
            json_value: this.SerializedValue
        };
    }


    protected constructor(tagKey : string)
    {
        this.tagKey = tagKey;
    }
}