import Color from "color";
import HierarchicalColorsInputGroup from "./components/hierarchical-colors-input-group";
import { TemplateTag } from "./template-tag";


export class TemplateMultiColorTag extends TemplateTag
{
    private static readonly TAG_TYPE : string = "multi_color";

    private colors : (Color | null)[];


    public constructor(tagKey : string, colorsCount : number | null, serializedValue : string | null)
    {
        super(tagKey);

        if(serializedValue === null) this.colors = Array(colorsCount ?? 0).fill(null);
        else
        {
            const hexColors = JSON.parse(serializedValue) as (string | null)[];

            let maxLength = colorsCount;
            if(maxLength === null) maxLength = hexColors.length;
            
            this.colors = Array(maxLength).fill(null);

            for(let colorsIndex = 0; colorsIndex < maxLength; colorsIndex++)
            {
                const hexColor = hexColors[colorsIndex];
                if(hexColor) this.colors[colorsIndex] = new Color(hexColor);
            }
        }
    }


    public get Colors() : (Color | null)[]
    {
        return Array.from(this.colors);
    }


    public set Colors(value : (Color | null)[])
    {
        this.colors = value;
    }


    public get TagType() : string
    {
        return TemplateMultiColorTag.TAG_TYPE;
    }


    public get SerializedValue() : string
    {
        return JSON.stringify(this.colors.map(color => color?.hex() ?? null));
    }


    public getManipulationComponent(onSubmit : () => void | Promise<void>) : JSX.Element | null
    {
        return (
            <HierarchicalColorsInputGroup
                colors={this.colors}
                label={this.TagKey}
                onSubmit={async updatedColors => this.updateAndSubmit(updatedColors, onSubmit)}
            />
        );
    }


    public fillWith(valueSourceTag : TemplateTag)
    {
        super.fillWith(valueSourceTag);

        const sourceHexColors = JSON.parse(valueSourceTag.SerializedValue) as (string | null)[];
        const sourceColors = sourceHexColors.map(sourceHexColor => sourceHexColor ? new Color(sourceHexColor) : null);
        
        for(let i = 0; i < sourceColors.length; i++)
        {
            this.colors[i] = sourceColors[i];
        }
    }


    private async updateAndSubmit(colors : (Color | null)[], submissionFunction : (templateTag : TemplateTag) => void | Promise<void>)
    {
        this.colors = colors;

        await submissionFunction(this);
    }
}