import Color from "color";
import { ColorTextInputGroupManager } from "./components/color-text-input-group-manager";
import { TemplateTag } from "./template-tag";


export class TemplateSingleColorTag extends TemplateTag
{
    private static readonly TAG_TYPE : string = "color";

    private color : Color | null;


    public constructor(tagKey : string, value : string | null)
    {
        super(tagKey);

        const sourceHexColor = value ? JSON.parse(value) as string : null;
        
        this.color = sourceHexColor ? new Color(sourceHexColor) : null;
    }


    public get TagType() : string
    {
        return TemplateSingleColorTag.TAG_TYPE;
    }


    public get SerializedValue() : string
    {
        return JSON.stringify(this.color?.hex() ?? null);
    }


    public getManipulationComponent(onSubmit : () => void | Promise<void>) : JSX.Element | null
    {
        return (
            <ColorTextInputGroupManager
                color={this.color}
                label={this.TagKey}
                onSubmit={async updatedColor => this.updateAndSubmit(updatedColor, onSubmit)}
            />
        );
    }


    public fillWith(valueSourceTag : TemplateTag)
    {
        super.fillWith(valueSourceTag);

        const sourceHexColor = JSON.parse(valueSourceTag.SerializedValue) as string | null;
        
        this.color = sourceHexColor ? new Color(sourceHexColor) : null;
    }


    private async updateAndSubmit(color : Color | null, submissionFunction : () => void | Promise<void>)
    {
        this.color = color;

        await submissionFunction();
    }
}