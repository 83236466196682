import React from "react";
import { ProductConfigurationReference } from "../api/product-configuration-reference";
import "./search-bar.css";


class SearchBar extends React.Component
{
    public props : SearchBarProps;
    public state = { searchTerm: '' };

    private static readonly COMPONENT_CONFIGURATION =
    {
        enabledPlaceholder: "Name oder Hash der Konfiguration eingeben ...",
        disabledPlaceholder: "Lädt ..."
    }


    public constructor(props : SearchBarProps)
    {
        super(props);
        this.props = props;
    }


    private handleInputChange(inputText : string)
    {
        this.setState({ searchTerm: inputText });
    }


    private handleFormSubmit(event : React.FormEvent)
    {
        event.preventDefault();

        this.props.onSubmit?.(this.state.searchTerm);
    }


    private get ConfigurationOptions() : React.ReactNode[]
    {
        return this.props.configurationReferences.map(reference =>
            <option key={reference.Name || reference.DefinitionHash} value={reference.Name || reference.DefinitionHash}>{reference.Name}</option>
        );
    }


    private get Placeholder() : string
    {
        if(this.props.isDisabled) return SearchBar.COMPONENT_CONFIGURATION.disabledPlaceholder;
        else return SearchBar.COMPONENT_CONFIGURATION.enabledPlaceholder;
    }


    public render() : React.ReactNode
    {
        return (
            <form className="search-bar" onSubmit={event => this.handleFormSubmit(event)}>
                <input
                    type="text"
                    placeholder={this.Placeholder}
                    onChange={event => this.handleInputChange(event.target.value)}
                    value={this.state.searchTerm}
                    spellCheck="false"
                    list="search-bar-list"
                    disabled={this.props.isDisabled}
                />
                <datalist id="search-bar-list">
                    {this.ConfigurationOptions}
                </datalist>
            </form>
        );
    }
}


interface SearchBarProps
{
    isDisabled: boolean;
    configurationReferences : ProductConfigurationReference[];
    onSubmit? : (searchTerm : string) => any;
}


export default SearchBar;