import Page from "./page";
import "./projects-view.css"
import { Project } from "../api/project";
import { useHistory } from 'react-router';
import { ProjectsList } from "./lists/projects-list";
import { ProductConfigurationPresentationSource } from "../product-configuration-presentation-source";
import { ProductConfigurationIdea } from "../product-configuration-idea";


const ProjectsView = (props : ProjectsPageProps) =>
{
    function handleProjectSelection(selectingProject : Project)
    {
        const selectingProjectId = selectingProject.Id;

        history.push(`${document.location.pathname}/${selectingProjectId}`);
    }


    const history = useHistory();

    return (
        <Page className="projects-view">
            <ProjectsList
                projects={props.projects}
                onProjectAddition={props.onProjectAddition}
                onProjectNameUpdate={props.onProjectNameUpdate}
                onProjectRemoval={props.onProjectRemoval}
                onProjectSelection={handleProjectSelection}
                availableConfigurationIdeas={props.availableConfigurationIdeas}
                availableConfigurationIdeasHaveBeenLoaded={props.availableConfigurationIdeasHaveBeenLoaded}
                configurationPresentationSourcesPerProject={props.configurationPresentationSourcesPerProject}
                configurationPresentationSourcesPerProjectHaveBeenLoaded={props.configurationPresentationSourcesPerProjectHaveBeenLoaded}
            />

            <div className="clear-cache-block">
                <input type="button" value="DAM-System-Cache leeren" onClick={props.onClearCacheClick} />
            </div>
        </Page>
    );
};


interface ProjectsPageProps
{
    projects : Project[];
    availableConfigurationIdeas: ProductConfigurationIdea[];
    availableConfigurationIdeasHaveBeenLoaded : boolean;
    configurationPresentationSourcesPerProject: Map<Project, ProductConfigurationPresentationSource[]>;
    configurationPresentationSourcesPerProjectHaveBeenLoaded : boolean;
    onProjectAddition : (projectName : string) => void | Promise<void>
    onProjectNameUpdate : (updatingProject : Project) => void | Promise<void>
    onProjectRemoval : (removingProject : Project) => void | Promise<void>
    onClearCacheClick(): void | Promise<void>;
}


export default ProjectsView;