import Color from "color";
import { DraggableColorBehaviour } from "../draggable-color-behaviour";
import "./colors-list-item.css";


const ColorsListItem = (props : ColorsListItemProps) =>
{
    function getOppositeBlackOrWhite(color : Color) : Color
    {
        if(color.luminosity() >= 0.5) return Color("#000000");
        else return Color("#ffffff");
    }


    async function handleClick(elementOfListItem : HTMLElement)
    {
        await navigator.clipboard.writeText(hexColor);

        const listItem = elementOfListItem.closest(".colors-list-item");
        listItem?.classList.add("flash-highlighted");
        listItem?.addEventListener("animationend", () =>
        {
            elementOfListItem.classList.remove("flash-highlighted");
        }, { once: true });
    }


    const hexColor = props.color.hex().toLowerCase();
    
    const style =
    {
        background: hexColor,
        color: getOppositeBlackOrWhite(props.color).hex()
    };

    let className = "colors-list-item";
    if(props.drawBorder) className = `${className} bordered`;

    let iconClassName = '';
    if(props.invertIconColor) iconClassName = "inversion-filtered";

    const draggableColorBehaviour = new DraggableColorBehaviour();

    return (
        <li
            style={style}
            className={className}
            draggable="true"
            onDragStart={event => draggableColorBehaviour.handleDragStart(event, props.color)}
            onClick={event => handleClick(event.target as HTMLElement)}
        >
            <img className={iconClassName} src={props.iconUrl} alt={props.iconAltText}></img>
            <span>{hexColor}</span>
        </li>
    );
};


interface ColorsListItemProps
{
    color : Color;
    iconUrl : string;
    iconAltText : string;
    drawBorder? : boolean;
    invertIconColor? : boolean;
}


export default ColorsListItem;