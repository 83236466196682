import "./color-mappings-list.css";
import { TemplateTag } from "../template-tag";
import { NamedColor } from "../named-color";
import { FunctionComponent } from "react";


const ColorMappingsList : FunctionComponent<ColorMappingsListProps> = props =>
{
    function getColorItems(templateTags : TemplateTag[]) : JSX.Element[]
    {
        return templateTags.map((templateTag, index) =>
        (
            <li key={index}>
                {templateTag.getManipulationComponent(() => props.onChange(templateTag))}
            </li>
        ));
    }


    const colorItems = getColorItems(props.colorTags);

    return <ul className="color-mappings-list">{colorItems}</ul>;
};


interface ColorMappingsListProps
{
    colorTags : TemplateTag[];
    colorSuggestionsWithSource : NamedColor[];
    onChange(changedTag : TemplateTag) : void | Promise<void>;
    children? : never;
}


export default ColorMappingsList;