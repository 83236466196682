import { Rendering } from "../rendering";
import App from "./app";
import ProxyImage from "./proxy-image";


const COMPONENT_CONFIGURATION =
{
    IMAGE_SIZE_CODE: "original"
};


const ClickableRendering = (props : ClickableRenderingProps) =>
{
    const url = props.rendering.getBestPreferredUrl(COMPONENT_CONFIGURATION.IMAGE_SIZE_CODE);
    const downloadUrl = props.rendering.getUrl(App.IMAGE_OPENING_SIZE_CODE, [App.IMAGE_OPENING_FILE_EXTENSION]);
    
    return (
        <ProxyImage
            src={url ?? undefined}
            clickDownloadUrl={downloadUrl}
            alt={props.rendering.cameraId ?? "Product Rendering"}
            onClick={async () => await props.onClicked(props.rendering)}
        />
    );
}


interface ClickableRenderingProps
{
    onClicked(resource : Rendering): void | Promise<void>;
    rendering : Rendering;
}


export default ClickableRendering;