import Color from "color";
import { NamedColor } from "../named-color";
import { TemplateFontTag } from "../template-font-tag";
import { IEvent, TypedEvent } from "../typed-event";
import { Utils } from "../utils";

export class FrameApi
{
    private readonly receivedColors : TypedEvent<NamedColor[]>;
    private readonly receivedFonts : TypedEvent<TemplateFontTag[]>;


    public constructor()
    {
        this.receivedColors = new TypedEvent<NamedColor[]>();
        this.receivedFonts = new TypedEvent<TemplateFontTag[]>();

        this.listenForPostMessages();
    }


    public sendSelectedColors(selectedColors : NamedColor[])
    {
        const colorsMessage : ColorsMessage =
        {
            messageType: "colors",
            colors: selectedColors.map(namedColor =>
            ({
                tagName: namedColor.name,
                hexColor: namedColor.color?.hex() ?? null
            }))
        };

        this.sendMessage(colorsMessage);
    }


    public sendSelectedFonts(filledFontTags : TemplateFontTag[])
    {
        const fontsMessage : FontsMessage =
        {
            messageType: "fonts",
            fontTags: filledFontTags
        };

        this.sendMessage(fontsMessage);
    }


    public sendReadyMessage()
    {
        const readyMessage : ReadyMessage = { messageType: "ready" };

        this.sendMessage(readyMessage);
    }


    public sendCloseMessage()
    {
        const closeMessage : CloseMessage = { messageType: "close" };

        this.sendMessage(closeMessage);
    }


    private listenForPostMessages()
    {
        window.addEventListener("message", event => this.handlePostMessageEvent(event));
    }


    private sendMessage(message : FrameMessage)
    {
        window.opener?.postMessage(message, '*');
    }


    private handlePostMessageEvent(event : MessageEvent<FrameMessage>)
    {
        const message = event.data;
        const { messageType } = message;
        
        switch(messageType)
        {
            case "colors": return void this.handleColorsMessage(message as ColorsMessage);
            case "fonts": return void this.handleFontsMessage(message as FontsMessage);
        }
    }


    handleColorsMessage(message : ColorsMessage)
    {
        const colors : NamedColor[] = message.colors.map(color =>
        ({
            name: color.tagName,
            color: Utils.CheckIfColorIsValid(color.hexColor) ? new Color(color.hexColor ?? '') : null
        }));

        this.receivedColors.trigger(colors);
    }


    handleFontsMessage(message : FontsMessage)
    {
        this.receivedFonts.trigger(message.fontTags);
    }


    public get ReceivedColors() : IEvent<NamedColor[]>
    {
        return this.receivedColors.expose();
    }


    public get ReceivedFonts() : IEvent<TemplateFontTag[]>
    {
        return this.receivedFonts.expose();
    }
}


interface FrameMessage
{
    messageType : "colors" | "fonts" | "ready" | "close";
}


interface ColorsMessage extends FrameMessage
{
    messageType : "colors";
    colors : { tagName : string; hexColor : string | null; }[];
}


interface FontsMessage extends FrameMessage
{
    messageType : "fonts";
    fontTags : TemplateFontTag[];
}


interface ReadyMessage extends FrameMessage
{
    messageType : "ready";
}


interface CloseMessage extends FrameMessage
{
    messageType : "close";
}