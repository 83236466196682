import { FunctionComponent, ReactNode } from "react";
import "./editable-linear-list.css";


const COMPONENT_CONFIGURATION =
{
    CLASS_NAME: "editable-linear-list"
};


export const EditableLinearList : FunctionComponent<EditableLinearListProps> = props =>
{
    function getLegendOrNothing() : JSX.Element | null
    {
        if(props.listLabel == null) return null;

        return <legend>{props.listLabel}</legend>;
    }


    function getClassName() : string
    {
        let className = COMPONENT_CONFIGURATION.CLASS_NAME;

        if(props.additionalClassName) className += (' ' + props.additionalClassName);
        if(!props.isEnabled) className += (" disabled");

        return className;
    }


    return (
        <fieldset className={getClassName()}>
            {getLegendOrNothing()}
            <ul className="stored-items-list">
                {props.items}
                <li className="adding-list-entry">
                    {props.additionItem}
                </li>
            </ul>
        </fieldset>
    );
}


interface EditableLinearListProps
{
    items : ReactNode[];
    additionItem : ReactNode;
    isEnabled : boolean;
    listLabel? : string;
    additionalClassName? : string;
}