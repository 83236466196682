import "./copy-to-clipboard-button.css";


const CopyToClipboardButton = (props : CopyToClipboardButtonProps) =>
{
    async function handleClick()
    {
        try
        {
            await window.navigator.clipboard.writeText(props.stringToCopy);
        }
        catch(error)
        {
            console.error(error);
        }
    }


    return (
        <button className="copy-to-clipboard-button" onClick={handleClick}>
            {props.children}       
        </button>
    );
};


interface CopyToClipboardButtonProps
{
    stringToCopy : string;
    children : React.ReactNode[] | React.ReactNode | null
}


export default CopyToClipboardButton;