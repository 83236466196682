import React from "react";
import "./login-view.css";


export class LoginView extends React.Component
{
    public props : LoginViewProps;
    public state : State;


    public constructor(props : LoginViewProps)
    {
        super(props);
        this.props = props;

        this.state = {
            username: '', password: ''
        };
    }


    public render()
    {
        return (
            <form className="login-view" onSubmit={event => this.handleFormSubmission(event)}>
    
                <fieldset>
                    <legend>Login</legend>
                    <label>
                        <span>Benutzer:</span>
                        <input
                            type="text"
                            onKeyPress={event => this.handleKeyPress(event)}
                            onInput={event => this.setState({ username: event.currentTarget.value })}
                        />
                    </label>
                    <label>
                        <span>Passwort:</span>
                        <input
                            type="password"
                            autoComplete="current-password"
                            onKeyPress={event => this.handleKeyPress(event)}
                            onInput={event => this.setState({ password: event.currentTarget.value })}
                        />
                    </label>
                    <input type="submit" value="Einloggen" />
                </fieldset>
    
            </form>
        );
    }


    private async handleKeyPress(event : React.KeyboardEvent<HTMLElement>) : Promise<void>
    {
        if(event.key === "Enter") await this.handleLogin();
    }


    private async handleFormSubmission(event : React.FormEvent<HTMLFormElement>)
    {
        event.preventDefault();
        this.handleLogin();
    }


    private async handleLogin() : Promise<void>
    {
        await this.props.onLogin(this.state.username, this.state.password);
    }
};


interface LoginViewProps
{
    onLogin : (username : string, password : string) => void | Promise<void>;
}


interface State
{
    username : string;
    password : string;
}