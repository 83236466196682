import { Font } from "../font";
import { ImageOption } from "../image-option";
import { TemplateFontTag } from "../template-font-tag";
import { TextSlotsList } from "./text-slots-list";

const FontsSlotsList = (props : FontsSlotsListProps) =>
{
    const options : ImageOption[] = props.availableFonts.map(font =>
    ({
        label: font.TypeFace ?? '',
        value: font.TypeFace ?? '',
        imageUrl: null,
        labelStyle: { fontFamily: font.TypeFace }
    }));


    const selectedValues : (string | null)[] = props.templateFontTagKeys.map(fontTagKey =>
    {
        const tagValueContainer = props.occupiedFontTags.find(occupiedTag => occupiedTag.tagName === fontTagKey);
        if(!tagValueContainer) return null;

        return tagValueContainer.typeFace;
    });


    function handleSelectionChange(selectedTypeFaces: (string | null)[])
    {
        const filledFontTags : TemplateFontTag[] = selectedTypeFaces.map((selectedTypeFace, index) => 
        ({
            tagName: props.templateFontTagKeys[index],
            typeFace: selectedTypeFace
        }));

        props.onSelectionChange(filledFontTags);
    }


    return (
        <TextSlotsList
            disabled={props.disabled}
            listLabel="Template Fonts"
            labels={props.templateFontTagKeys}
            selectedValues={selectedValues}
            options={options}
            slotsCount={props.templateFontTagKeys.length}
            onSelectionChange={handleSelectionChange}
        />
    );
}


export interface FontsSlotsListProps
{
    availableFonts : Font[];
    occupiedFontTags : TemplateFontTag[];
    templateFontTagKeys : string[];
    disabled : boolean;
    onSelectionChange : (filledFontTags : TemplateFontTag[]) => void | Promise<void>
}


export default FontsSlotsList;