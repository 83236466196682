import { FunctionComponent } from "react";
import "./product-configuration-definitions-list-item-label.css";


export const ProductConfigurationListItemLabel : FunctionComponent<ProductConfigurationListItemLabelProps> = props =>
{
    function getContent(text : string | null, imageUrl : string | null) : JSX.Element[]
    {
        let textElement = null;
        if(text !== null) textElement = <span className="label-text">{text}</span>;

        let imageElement = null;
        if(imageUrl !== null)
        {
            imageElement = (
                <div className="label-image-container">
                    <img src={imageUrl} alt={text || "List Item"} />
                </div>
            );
        }

        const createdElements = [textElement, imageElement].filter(element => element !== null) as JSX.Element[];

        return createdElements;
    }


    return (
        <button
            className="product-configuration-definitions-list-item-label"
            disabled={false}
            onClick={props.click.handle}
        >
            {getContent(props.text, props.imageUrl)}
        </button>
    );
};


interface ProductConfigurationListItemLabelProps
{
    click : ClickProps;
    text : string | null;
    imageUrl : string | null;
}


export interface ClickProps
{
    isPossible : boolean;
    handle?() : void | Promise<void>;
}