import Color from "color";
import React from "react";
import { DraggableColorBehaviour } from "../draggable-color-behaviour";
import { Utils } from "../utils";
import "./color-text-input.css";


export class ColorTextInput extends React.Component<ColorTextInputProps, State>
{
    public props : ColorTextInputProps;
    public state : State;

    private readonly colorPickerRef : React.RefObject<HTMLInputElement>;
    private readonly draggableColorBehaviour : DraggableColorBehaviour;


    public static GenerateInputId() : string
    {
        return "color-text-input-" + Utils.GenerateRandomUint();
    }


    public constructor(props : ColorTextInputProps)
    {
        super(props);
        this.props = props;

        this.state = { colorPickerIsOpen: false };

        this.colorPickerRef = React.createRef();

        this.draggableColorBehaviour = new DraggableColorBehaviour();
    }


    public componentDidMount()
    {
        // Used to detect if color picker is closed in some way. If not changed, no saving is needed.
        this.colorPickerRef.current?.addEventListener("change", async () =>
        {
            this.setState({ colorPickerIsOpen: false });            
            await this.props.onSubmit();
        });
    }


    public render() : JSX.Element
    {
        return (
            <div className={this.ClassName} onBlur={async () => await this.props.onSubmit()} onKeyPress={event => this.handleKeyPress(event)}>
                <input
                    type="text"
                    className="hex-color-input"
                    id={this.props.textInputId}
                    value={this.LowercaseInputText ?? ''}
                    draggable="true"
                    style={this.TextInputStyle}
                    onInput={event => this.handleInput(event)}
                    onDragStart={event => this.handleDragStart(event)}
                    onDragEnd={() => this.props.onDragEnd?.()}
                    onDragOver={event => event.preventDefault()}
                    onDrop={event => this.handleDrop(event)}
                />
                <input
                    className="hidden-color-picker-input"
                    type="color"
                    value={this.InputTextAsHexColor ?? undefined}
                    ref={this.colorPickerRef}
                    onInput={event => this.handleInput(event)}
                />
                <button className="color-picker-button" onClick={() => this.toggleColorPicker()}>
                    <img className="eyedropper-icon" src="/images/icon-eyedropper.svg" alt="Eyedropper" />
                </button>
            </div>
        );
    }


    private get LowercaseInputText() : string | null
    {
        return this.props.inputText?.toLowerCase() ?? null;
    }


    private get InputTextAsHexColor() : string | null
    {
        const lowercaseInputText = this.LowercaseInputText;

        if(Utils.CheckIfColorIsValid(lowercaseInputText)) return lowercaseInputText;
        else return null;
    }


    private get InputTextAsColor() : Color | null
    {
        if(Utils.CheckIfColorIsValid(this.LowercaseInputText)) return new Color(this.LowercaseInputText ?? '');
        else return null;
    }


    private get ClassName() : string
    {
        let className = "color-text-input";

        if(this.InputTextAsHexColor === null) className += " empty-selection";
        else
        {       
            const textInputBackgroundCssColor = this.InputTextAsHexColor;
            if(textInputBackgroundCssColor)
            {
                const textInputBackgroundColor = new Color(textInputBackgroundCssColor);
                const backgroundIsDark = textInputBackgroundColor.luminosity() < 0.5;

                if(backgroundIsDark) className += " dark-colored";
            }
        }

        return className;
    }


    private get TextInputStyle() : React.CSSProperties
    {
        if(this.InputTextAsHexColor === null) return {};

        return { background: this.InputTextAsHexColor };
    }


    private handleInput(event : React.FormEvent<HTMLInputElement>)
    {
        const inputValue = event.currentTarget.value.toLowerCase();

        this.props.onInput(inputValue);
    }


    private async handleKeyPress(event : React.KeyboardEvent)
    {
        if(event.key === "Enter") await this.props.onSubmit();
    }


    private async handleDrop(event : React.DragEvent<HTMLElement>)
    {
        const droppedText = event.dataTransfer.getData("text/plain").toLowerCase();

        await this.props.onReceivedDrop(droppedText);
        await this.props.onSubmit();
    }


    private handleDragStart(event : React.DragEvent<HTMLElement>)
    {
        this.draggableColorBehaviour.handleDragStart(event, this.InputTextAsColor);
        this.props.onDragStart?.();
    }


    private toggleColorPicker()
    {
        if(this.state.colorPickerIsOpen) this.closeColorPicker();
        else this.openColorPicker();
    }


    private openColorPicker()
    {
        this.colorPickerRef.current?.click();

        this.setState({ colorPickerIsOpen: true });
    }


    private closeColorPicker()
    {
        this.colorPickerRef.current?.blur();

        this.setState({ colorPickerIsOpen: false });
    }
};


export interface ColorTextInputProps
{
    textInputId : string;
    inputText : string | null;
    onInput(lowercaseValue : string) : void;
    onDragStart?() : void;
    onDragEnd?() : void;
    onReceivedDrop(lowercaseValue : string) : Promise<void>;
    onSubmit() : void | Promise<void>;
}


interface State
{
    colorPickerIsOpen : boolean;
}