import Color from "color";
import { Inspiration } from "./api/inspiration";
import { ProductConfigurationReference } from "./api/product-configuration-reference";
import { Rendering } from "./rendering";
import { NamedColor } from "./named-color";
import { TemplateTagFactory } from "./template-tag-factory";
import { ServerApi } from "./api/server-api";
import { TemplateTag } from "./template-tag";


export class ProductConfigurationIdea
{
    private static readonly EmptyDefaultTemplateTags : Partial<ServerApi.TemplateField>[] =
    [{
        template_tag_name: "background-color",
        tag_type: "multi_color"
    },
    {
        template_tag_name: "background-detail-1",
        tag_type: "color"
    },
    {
        template_tag_name: "background-detail-2",
        tag_type: "color"
    }];

    private readonly reference : ProductConfigurationReference;
    private readonly inspiration : Inspiration | null;
    private readonly renderings : Rendering[];


    public constructor(reference : ProductConfigurationReference, inspiration : Inspiration | null, renderings : Rendering[])
    {
        this.reference = reference;
        this.inspiration = inspiration;
        this.renderings = renderings;
    }


    public get Reference() : ProductConfigurationReference
    {
        return this.reference;
    }


    public get Inspiration() : Inspiration | null
    {
        return this.inspiration;
    }


    public get HasInspiration()
    {
        return !!this.inspiration;
    }


    public get Renderings() : Rendering[]
    {
        return this.renderings;
    }


    public get CameraIds() : string[]
    {
        const possiblyNullCameraIds = this.renderings.map(rendering => rendering.cameraId);
        const cameraIds = possiblyNullCameraIds.filter(cameraId => cameraId != null) as string[];

        return cameraIds;
    }


    public get InspirationColors() : Color[]
    {
        if(!this.inspiration) return [];

        const inspirationColors = [];

        if(this.inspiration.backgroundColor) inspirationColors.push(this.inspiration.backgroundColor);
        inspirationColors.push(...this.inspiration.productColors);

        return inspirationColors;
    }


    public get NamedInspirationColors() : NamedColor[]
    {
        if(!this.inspiration) return [];

        const namedInspirationColors = [];

        if(this.inspiration.backgroundColor)
        {
            const namedBackgroundColor : NamedColor =
            {
                color: this.inspiration.backgroundColor,
                name: "inspiration.backgroundColor"
            }
            namedInspirationColors.push(namedBackgroundColor);
        }

        const productColorsBasePath = "inspiration.productColors"
        for(let i = 0; i < this.inspiration.productColors.length; i++)
        {
            const namedProductColor : NamedColor =
            {
                color: this.inspiration.productColors[i],
                name: `${productColorsBasePath}[${i}]`
            }
            namedInspirationColors.push(namedProductColor);
        }

        return namedInspirationColors;
    }


    public get ReferenceNameOrSkeletonId() : string
    {
        return this.reference.NameOrSkeletonId;
    }


    public get DefaultTemplateTags() : TemplateTag[]
    {
        const inspirationHexColors = this.InspirationColors.map(color => color.hex());

        const defaultTagsLength = Math.min(ProductConfigurationIdea.EmptyDefaultTemplateTags.length, inspirationHexColors.length);

        const templateTagFactory = new TemplateTagFactory();

        const defaultTemplateTags = [];

        for(let defaultTagIndex = 0; defaultTagIndex < defaultTagsLength; defaultTagIndex++)
        {
            const { template_tag_name, tag_type } = ProductConfigurationIdea.EmptyDefaultTemplateTags[defaultTagIndex];

            let tagValue = null;
            if(tag_type === "color") tagValue = inspirationHexColors[defaultTagIndex];
            else if(tag_type === "multi_color") tagValue = [inspirationHexColors[defaultTagIndex]];

            const backendTemplateTag : ServerApi.TemplateField =
            {
                template_tag_name: template_tag_name ?? '',
                tag_type: tag_type ?? '',
                json_value: JSON.stringify(tagValue)
            };
            
            const defaultTemplateTag = templateTagFactory.createTemplateTagFromBackend(backendTemplateTag);
            defaultTemplateTags.push(defaultTemplateTag);
        }
        
        return defaultTemplateTags;
    }


    public getRendering(cameraId : string) : Rendering | null
    {        
        return (this.renderings.find(rendering => rendering.cameraId === cameraId) ?? null);
    }


    public getRenderingInPreferredFormatUrl(cameraId : string, sizeCode : string) : string | null
    {        
        const rendering = this.renderings.find(rendering => rendering.cameraId === cameraId);

        const renderingUrl = rendering?.getBestPreferredUrl(sizeCode);
        
        return renderingUrl ?? null;
    }


    public checkIfReferenceMatches(reference : ProductConfigurationReference) : boolean
    {
        return this.reference.checkIfEquals(reference);
    }
}