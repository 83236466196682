import ButtonLink from "./button-link";


const COMPONENT_CONFIGURATION =
{
    WEBVIEWER_ORIGIN: "https://preview-development.create-3d.de",
    WEBVIEWER_OPENING_PATH: "string.php",
    WEBVIEWER_OPENING_PATH_QUERY_PARAMETER_NAME: "value",
    LABEL: "Im WebViewer öffnen"
}


const WebViewerButtonLink = (props : WebViewerButtonLinkProps) =>
{
    const configurationDefinition = props.productConfigurationDefinitionString;
    const webViewerConfiguration = configurationDefinition.replace('|', "|Environment|01||1|0|0|0|4|");

    const path = COMPONENT_CONFIGURATION.WEBVIEWER_OPENING_PATH;
    const origin = COMPONENT_CONFIGURATION.WEBVIEWER_ORIGIN;
    const queryParameterName = COMPONENT_CONFIGURATION.WEBVIEWER_OPENING_PATH_QUERY_PARAMETER_NAME;

    const webViewerUrl = new URL(path, origin);
    webViewerUrl.searchParams.set(queryParameterName, webViewerConfiguration);

    return <ButtonLink url={webViewerUrl} opensInNewTab>{COMPONENT_CONFIGURATION.LABEL}</ButtonLink>;
}


interface WebViewerButtonLinkProps
{
    productConfigurationDefinitionString : string;
}


export default WebViewerButtonLink;