import Page from "./page";
import "./four-oh-four-page.css";
import { Link } from "react-router-dom";


const FourOhFourPage = () =>
{
    return (
        <Page className="four-oh-four-page">
            <div>
                <span>😭</span>
                <div>
                    <span>404</span>
                    <Link to="/">Let's go home.</Link>
                </div>
                <span>😭</span>
            </div>
        </Page>
    );
}


export default FourOhFourPage;