import { ServerApi } from "./api/server-api";
import { TemplateTag } from "./template-tag";


export class TemplateContentField extends TemplateTag
{
    private static readonly TAG_TYPE : string = "content";

    private content : string | null;


    public constructor(tagKey : string, value : string | null)
    {
        super(tagKey);
        
        this.content = null;
        if(value !== null) this.content = JSON.parse(value);
    }


    public get Content() : string | null
    {
        return this.content;
    }


    public get TagType() : string
    {
        return TemplateContentField.TAG_TYPE;
    }


    public get SerializedValue() : string
    {
        return JSON.stringify(this.content);
    }


    public getManipulationComponent(onSubmit : () => void | Promise<void>) : JSX.Element | null
    {
        return null; // currently not implemented
    }


    public fillWith(valueSourceTag : TemplateTag)
    {
        super.fillWith(valueSourceTag);

        this.content = JSON.parse(valueSourceTag.SerializedValue) as string | null;
    }


    public toBackendTemplateField() : ServerApi.TemplateField
    {
        const backendField = super.toBackendTemplateField();

        backendField.json_value = this.SerializedValue;

        return backendField;
    }
}