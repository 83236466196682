export interface TemplateTagKey
{
    tagType : TagType;
    name : string;
    subTagsCount : number;
}


export enum TagType
{
    Color,
    MultiColor,
    Content,
    Font
}