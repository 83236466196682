import { ServerApi } from "./api/server-api";
import { TemplateContentField } from "./template-content-tag";
import { TemplateMultiColorTag } from "./template-multi-color-tag";
import { TemplateSingleColorTag } from "./template-single-color-tag";
import { TemplateTag } from "./template-tag";
import { TagType, TemplateTagKey } from "./template-tag-key";


export class TemplateTagFactory
{
    public static ConvertStringToTagType(tagTypeString : string) : TagType
    {
        switch(tagTypeString)
        {
            case "content": return TagType.Content;
            case "color": return TagType.Color;
            case "multi_color": return TagType.MultiColor;
            case "font": return TagType.Font;
        }

        throw Error(`Could not convert tag type ${tagTypeString}`);
    }


    public createTemplateTagFromSlot(mappingTarget : TemplateTagKey) : TemplateTag
    {
        const tagType = mappingTarget.tagType;

        const { name, subTagsCount } = mappingTarget;
        
        switch(tagType)
        {
            case TagType.Content: return new TemplateContentField(name, null);
            case TagType.Color: return new TemplateSingleColorTag(name, null);
            case TagType.MultiColor: return new TemplateMultiColorTag(name, subTagsCount, null);

            default: throw new Error("Tried to create unsupported template tag.");
        }
    }


    public createTemplateTagFromBackend(backendTag : ServerApi.TemplateField) : TemplateTag
    {
        const tagType = TemplateTagFactory.ConvertStringToTagType(backendTag.tag_type);

        const { template_tag_name : name } = backendTag;
        
        let value = backendTag.json_value;
        if(value === JSON.stringify(null)) value = null;
        
        switch(tagType)
        {
            case TagType.Content: return new TemplateContentField(name, value);
            case TagType.Color: return new TemplateSingleColorTag(name, value);
            case TagType.MultiColor: return new TemplateMultiColorTag(name, null, value);

            default: throw new Error("Tried to create unsupported template tag.");
        }
    }
}