export class ProductConfigurationReference
{
    private readonly definition : string;
    private readonly definitionHash : string;
    private readonly name : string | null;


    public constructor(definition : string, definitionHash : string, name : string | null)
    {
        this.definition = definition;
        this.definitionHash = definitionHash;
        this.name = name;    
    }


    public get Definition()
    {
        return this.definition;
    }


    public get DefinitionHash() : string
    {
        return this.definitionHash;
    }


    public get Name() : string | null
    {
        return this.name;
    }


    public get NameOrSkeletonId()
    {
        const skeletonId = this.definition.split('|')[0];

        return this.name || skeletonId;
    }


    public get NameOrDefinitionHash() : string
    {
        return this.name || this.definitionHash;
    }


    public checkIfEquals(other : ProductConfigurationReference) : boolean
    {
        let isEqual = true;
        
        isEqual = isEqual && (other.definition === this.definition);
        isEqual = isEqual && (other.definitionHash === this.definitionHash);
        isEqual = isEqual && (other.name === this.name);

        return isEqual;
    }
}