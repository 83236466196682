import React from "react";
import { OptionsInputProps } from "../options-input-props";
import { Utils } from "../utils";
import "./options-input.css";
import "./text-input-dropdown.css";


const TextInputDropdown = (props : OptionsInputProps) =>
{
    function getDatalistOptions() : JSX.Element[]
    {
        return props.options.map(datalistOption => <option value={datalistOption.value}>{datalistOption.label}</option>);
    }


    async function handleInput(event : React.FormEvent<HTMLInputElement>)
    {
        const inputText = event.currentTarget.value;

        await props.onInput(inputText);
    }
    

    async function handleKeyPress(event : React.KeyboardEvent<HTMLInputElement>)
    {
        if(event.key === "Enter") await props.onSubmit(props.value);
    }
    

    const datalistId = ("text-input-dropdown-" + Utils.GenerateRandomUint());

    return (
        <div className="text-input-dropdown options-input">
            <input
                list={datalistId}
                onInput={handleInput}
                onKeyPress={handleKeyPress}
                type="text"
                value={props.value ?? ''}
                disabled={props.disabled}
            />
            <datalist id={datalistId}>
                {getDatalistOptions()}
            </datalist>
        </div>
    );
}


export default TextInputDropdown;