import React from "react";
import { OptionsInputProps } from "../options-input-props";
import "./text-image-dropdown.css";


export class TextImageDropdown extends React.Component
{
    private static CONFIGURATION =
    {
        OPTION_KEYS:
        {
            DEFAULT: -1,
            HEADER: -2
        }
    };


    public props : OptionsInputProps;
    public state : State;


    public constructor(props : OptionsInputProps)
    {
        super(props);
        this.props = props;

        this.state = { isDroppedDown: false };
    }


    public render()
    {
        const selectValue = this.props.value ?? this.props.defaultValue ?? null;

        let className = "text-image-dropdown options-input";
        if(this.state.isDroppedDown) className += " dropped-down";
        if(this.props.disabled) className += " disabled";

        return (
            <div id={this.props.id} className={className} tabIndex={0} onClick={() => this.toggleExpansion()}>
                {this.getHeaderElement(selectValue)}
                <ul className="options-container">
                    {this.getOptionElements()}
                </ul>
            </div>
        );
    }


    private getHeaderElement(selectedValue : string | null) : JSX.Element
    {
        const selectedOption = this.props.options.find(option => option.value === selectedValue);
        const label = selectedOption?.label || null;
        const imageUrl = selectedOption?.imageUrl || null;

        const imageOrNothing = imageUrl ? <img className="option-image" src={imageUrl} alt="Dropdown Option" /> : null;

        return (
            <div className="header" data-value={selectedValue ?? ''} key={TextImageDropdown.CONFIGURATION.OPTION_KEYS.HEADER}>
                <span className="option-label">{label}</span>
                <div className="option-image-container">
                    {imageOrNothing}
                </div>
                <img className="drop-arrow" src="/images/dropdown-arrow.png" alt="Dropdown Arrow" />
            </div>
        );
    }


    private getOptionElements() : JSX.Element[]
    {
        const optionElements = [];

        const defaultOption = this.getOptionElement(null, null, null, 0);

        let indexOffset = 0;

        if(!this.props.defaultValue)
        {
            optionElements.push(defaultOption);
            indexOffset = 1;
        }

        const optionElementsFromOptions = this.props.options.map((option, index) =>
        {
            const { value, label, imageUrl } = option;

            return this.getOptionElement(value, label, imageUrl, (index + indexOffset));
        });

        optionElements.push(...optionElementsFromOptions);

        return optionElements;
    }


    private getOptionElement(value : string | null, label : string | null, imageUrl : string | null, index : number) : JSX.Element
    {
        const imageOrNothing = imageUrl ? <img className="option-image" src={imageUrl} alt="Dropdown Option" /> : null;

        const optionStyle = { top: ((1 + index) * 100) + '%' };

        return (
            <li className="option" style={optionStyle} data-value={value ?? ''} onClick={event => this.handleOptionClick(event)} key={index}>
                <span className="option-label">{label}</span>
                <div className="option-image-container">
                    {imageOrNothing}
                </div>
            </li>
        );
    }


    private async handleOptionClick(event : React.MouseEvent<HTMLLIElement>)
    {
        const optionValue = event.currentTarget.getAttribute("data-value") || null;

        await this.props.onInput(optionValue);
        await this.props.onSubmit(optionValue);
    }


    private toggleExpansion()
    {
        const isDroppedDown = !this.state.isDroppedDown;

        this.setState({ isDroppedDown });
    }
};


interface State
{
    isDroppedDown : boolean;
}