import App from "./components/app";

export class Rendering
{
    public cameraId : string;
    public files : AssetFile[];

    public constructor(cameraId : string, files : AssetFile[])
    {
        this.cameraId = cameraId;
        this.files = files;
    }


    public getBestPreferredUrl(sizeCode : string) : string | null
    {
        return this.getUrl(sizeCode, Array.from(App.PREFERRED_FILE_EXTENSIONS));
    }


    public getUrl(sizeCode : string, prioritizedFileExtensions : string[]) : string | null
    {
        for(const fileExtension of prioritizedFileExtensions)
        {
            const preferredFile = this.files.find(file => file.sizeCode === sizeCode && file.extension.toLowerCase() === fileExtension.toLowerCase());
            if(preferredFile) return preferredFile.url;
        }

        return null;
    }
}


export interface AssetFile
{
    sizeCode : "original" | "pre" | "thm" | "col";
    extension : string;
    url : string;
}