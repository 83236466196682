import { OptionsInputProps } from "../options-input-props";
import "./text-dropdown.css";


const TextDropdown = (props : OptionsInputProps) =>
{
    async function handleChange(event : React.ChangeEvent<HTMLSelectElement>)
    {
        const updatedValue = event.target.value || null;

        await props.onInput(updatedValue);
        await props.onSubmit(updatedValue);
    }


    function getOptionElements() : JSX.Element[]
    {
        const selectOptions = [];

        if(!props.defaultValue) selectOptions.push(<option key={-1} value=""></option>);

        const optionElementsFromOptions = props.options.map((option, index) =>
        {
            return <option key={index} value={option.value} style={option.labelStyle}>{option.label}</option>
        });

        selectOptions.push(...optionElementsFromOptions);

        return selectOptions;
    }


    function getSelectStyle(selectValue : string) : React.CSSProperties
    {
        const selectedOption = props.options.find(option => option.value === selectValue);
        if(!selectedOption) return {};

        const selectStyle = selectedOption.labelStyle;
        if(!selectStyle) return {};

        return selectStyle;
    }


    const selectValue = props.value ?? props.defaultValue ?? '';


    return (
        <select
            id={props.id}
            className="text-dropdown options-input"
            value={selectValue}
            style={getSelectStyle(selectValue)}
            disabled={props.disabled}
            onChange={handleChange}
        >
            {getOptionElements()}
        </select>
    );
};


export default TextDropdown;