import { FunctionComponent } from "react";
import { ClickProps } from "./product-configuration-definitions-list-item-label";
import "./projects-list-item-label.css";


export namespace ProjectsListItemLabel
{
    export const Component : FunctionComponent<ProjectsListItemLabelProps> = props => 
    {
        function getWrappedImagesOrLoadingIndicator() : JSX.Element | JSX.Element[]
        {
            if(props.imagesAreLoading) return <span>Lädt ...</span>;
            else return getWrappedImages();
        }


        function getWrappedImages() : JSX.Element[]
        {
            return props.imageUrls.map(imageUrl =>
            {
                return (
                    <div className="image-container">
                        <img src={imageUrl} alt={COMPONENT_CONFIGURATION.PREVIEW_IMAGES_ALT_TEXT} />
                    </div>
                );
            });
        }


        return (
            <button
                className="projects-list-item-label"
                disabled={!props.click.isPossible}
                onClick={props.click.handle}
            >
                <div className="inner-section text-container">
                    <span>{props.text}</span>
                </div>
                <div className="inner-section images">
                    {getWrappedImagesOrLoadingIndicator()}
                </div>
            </button>
        );
    };


    export const COMPONENT_CONFIGURATION = Object.freeze(
    {
        PREVIEW_CONFIGURATIONS_COUNT: 3,
        PREVIEW_IMAGES_ALT_TEXT: "Konfigurationsvorschau"
    });
}


interface ProjectsListItemLabelProps
{
    text : string;
    imageUrls : string[];
    imagesAreLoading : boolean;
    click : ClickProps;
}