import WebViewerButtonLink from "./webviewer-button-link";
import CopyToClipboardButton from "./copy-to-clipboard-button";
import "./product-configuration-preview-top-block.css";
import ProxyImage from "./proxy-image";
import App from "./app";
import { ProductConfigurationPresentationSource } from "../product-configuration-presentation-source";
import { ProductConfigurationIdea } from "../product-configuration-idea";


const COMPONENT_CONFIGURATION =
{
    RENDERING_SIZE_CODE: "original",
    NOT_LOADED_MESSAGE: "Lädt Ansicht ...",
    NO_DAM_DATA_ERROR_MESSAGE: "Keine Daten im DAM-System gefunden.",
    NO_CAMERA_ERROR_MESSAGE: "Keine Kamera gewählt.",
    NO_RENDERING_ERROR_MESSAGE: "Kein passendes Rendering gefunden."
};


const ProductConfigurationPreviewTopBlock = (props : ProductPreviewProps) =>
{  
    function getRenderingImageOrNoticeText(cameraIdToShow : string | null) : JSX.Element
    {
        let errorText = "";

        if(cameraIdToShow == null) errorText = COMPONENT_CONFIGURATION.NO_CAMERA_ERROR_MESSAGE;
        else if(!props.configurationIdeaHasBeenLoaded) errorText = COMPONENT_CONFIGURATION.NOT_LOADED_MESSAGE;
        else if(!props.configurationIdea) errorText = COMPONENT_CONFIGURATION.NO_DAM_DATA_ERROR_MESSAGE;
        
        const thereIsAnImage  = (cameraIdToShow != null && props.configurationIdeaHasBeenLoaded && props.configurationIdea);
        if(!thereIsAnImage)
        {
            return <span className="notice-text" onClick={() => props.onSelected(props.configurationPresentationSource)}>{errorText}</span>;
        }

        const rendering = props.configurationIdea?.Renderings.find(rendering => rendering.cameraId === cameraIdToShow);
        const renderingUrl = rendering?.getBestPreferredUrl(COMPONENT_CONFIGURATION.RENDERING_SIZE_CODE);

        if(!renderingUrl) return <span className="notice-text" onClick={() => props.onSelected(props.configurationPresentationSource)}>{COMPONENT_CONFIGURATION.NO_RENDERING_ERROR_MESSAGE}</span>;

        const renderingDownloadUrl = rendering?.getUrl(App.IMAGE_OPENING_SIZE_CODE, [App.IMAGE_OPENING_FILE_EXTENSION]);

        return (
            <ProxyImage
                className="product-main-image"
                onClick={() => props.onSelected(props.configurationPresentationSource)}
                alt="Product Rendering"
                src={renderingUrl}
                clickDownloadUrl={renderingDownloadUrl ?? null}
            />
        );
    }

    const configurationDefinition = props.configurationPresentationSource.Reference.Definition;

    return (
        <div className="product-configuration-preview-top-block">
            <div className="top-buttons">
                <CopyToClipboardButton stringToCopy={configurationDefinition}>Definition kopieren</CopyToClipboardButton>
                <WebViewerButtonLink productConfigurationDefinitionString={configurationDefinition} />
            </div>
            {getRenderingImageOrNoticeText(props.cameraIdToShow)}
            <span className="product-name" onClick={() => props.onSelected(props.configurationPresentationSource)}>
                {props.configurationPresentationSource.ReferenceNameOrSkeletonId}
            </span>
        </div>
    );
};


interface ProductPreviewProps
{
    configurationPresentationSource : ProductConfigurationPresentationSource;
    configurationIdea : ProductConfigurationIdea | null;
    configurationIdeaHasBeenLoaded : boolean;
    cameraIdToShow : string | null;
    onSelected : (selectedConfiguration : ProductConfigurationPresentationSource) => void | Promise<void>;
}


export default ProductConfigurationPreviewTopBlock;