import React from "react";
import "./collapsible-block.css";


export class CollapsibleBlock extends React.Component
{
    public props : CollapsibeBlockProps;
    public state : State;


    private get ClassName() : string
    {
        if(this.state.isCollapsed) return `collapsible-block collapsed`;
        else return `collapsible-block`;
    }


    public constructor(props : CollapsibeBlockProps)
    {
        super(props);
        this.props = props;

        this.state =
        {
            isCollapsed: !!this.props.isCollapsedByDefault
        };
    }


    public render()
    {
        return (
            <div className={this.ClassName}>
                <div className="header">
                    <button onClick={() => this.toggleCollapse()}>
                        <img className="arrow" src="/images/dropdown-arrow.png" alt="Collapse or Expand" />
                    </button>
                    <span className="title">{this.props.title}</span>
                </div>
                <div className="children-container">
                    {this.props.children}
                </div>
            </div>
        );
    }


    private toggleCollapse()
    {
        const isCollapsed = !this.state.isCollapsed;

        this.setState({ isCollapsed });
    }
}


interface CollapsibeBlockProps
{
    children? : React.ReactNode[] | React.ReactNode;
    title? : string;
    isCollapsedByDefault? : boolean;
}


interface State
{
    isCollapsed : boolean;
}