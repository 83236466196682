import "./page.css"


const Page = (props : PageProps) =>
{
    let pageClassName = "page";
    if(props.className) pageClassName += (' ' + props.className);
    const className = props.isActive ? (pageClassName + " active") : pageClassName;

    return (
        <div className={className}>
            {props.children}
        </div>
    );
}


interface PageProps
{
    children: any | any[]
    isActive?: boolean;
    className? : string;
}


export default Page;