import Color from "color";
import { ProductConfigurationIdea } from "../product-configuration-idea";
import { ProductConfigurationPresentationSource } from "../product-configuration-presentation-source";
import { Rendering } from "../rendering";
import { TemplateTag } from "../template-tag";
import { Inspiration } from "./inspiration";
import { Project } from "./project";
import { ServerApi } from "./server-api";
import { UnsavedProject } from "./unsaved-project";
import { LoginState } from "../login-state";
import { TemplateTagFactory } from "../template-tag-factory";
import { Font } from "../font";
import { TemplateFontTag } from "../template-font-tag";
import { ProductConfigurationReference } from "./product-configuration-reference";


export class ApiAdapter
{
    public static ConvertBackendProjects(projects : ServerApi.Project[]) : Project[]
    {
        return projects.map(this.ConvertBackendProject);
    }


    public static ConvertBackendProject(project : ServerApi.Project) : Project
    {
        return Project.BuildFromBackendProject(project);
    }


    public static ConvertUnsavedProject(project : UnsavedProject) : ServerApi.UnsavedProject
    {
        return {
            name: project.Name,
            camera_ids: project.CameraIds.map(id => id || ''),
            fonts: ApiAdapter.ConvertProjectFonts(project.Fonts),
            template_document_ids: project.TemplateDocumentIds.map(id => id || ''),
            product_configuration_reference_subpaths: project.ProductConfigurationReferenceSubpaths
        };
    }


    public static ConvertProjectPatch(updatingProperties : Partial<UnsavedProject>) : Partial<ServerApi.UnsavedProject>
    {
        const updatingBackendProperties : Partial<ServerApi.UnsavedProject> = {};

        const { Name, CameraIds, Fonts, TemplateDocumentIds  } = updatingProperties;

        if(CameraIds != null) updatingBackendProperties.camera_ids = CameraIds.map(id => id || '');
        if(Fonts != null) updatingBackendProperties.fonts = ApiAdapter.ConvertProjectFonts(Fonts);
        if(TemplateDocumentIds != null) updatingBackendProperties.template_document_ids = TemplateDocumentIds.map(id => id || '');
        if(Name != null) updatingBackendProperties.name = Name;

        return updatingBackendProperties;
    }


    public static ConvertProductConfigurationPresentationSourcePatch(updatingProperties : Partial<ProductConfigurationPresentationSource>) : Partial<ServerApi.ProductConfigurationPresentationSource>
    {
        const updatingBackendProperties : Partial<ServerApi.ProductConfigurationPresentationSource> = {};

        const { TemplateFields: templateFields } = updatingProperties;

        if(templateFields) updatingBackendProperties.template_fields = ApiAdapter.ConvertTemplateFields(templateFields);

        return updatingBackendProperties;
    }


    public static ConvertBackendAvailableFonts(backendFonts : ServerApi.Font[]) : Font[]
    {
        return backendFonts.map(ApiAdapter.ConvertBackendAvailableFont);
    }


    public static ConvertLoginState(backendLoginState : ServerApi.LoginState) : LoginState
    {
        return {
            username: backendLoginState.username,
            isLoggedIn: backendLoginState.logged_in
        };
    }


    private static ConvertTemplateFields(templateFields : TemplateTag[]) : ServerApi.TemplateField[]
    {
        return templateFields.map(ApiAdapter.ConvertTemplateField);
    }


    private static ConvertTemplateField(templateField : TemplateTag) : ServerApi.TemplateField
    {
        const backendTemplateField = templateField.toBackendTemplateField();

        return backendTemplateField;
    }


    public static ConvertBackendProductConfigurationPresentationSources(presentationSources : ServerApi.ProductConfigurationPresentationSource[]) : ProductConfigurationPresentationSource[]
    {
        return presentationSources.map(ApiAdapter.ConvertBackendProductConfigurationPresentationSource);
    }


    public static ConvertBackendProductConfigurationPresentationSource(backendPresentationSource : ServerApi.ProductConfigurationPresentationSource) : ProductConfigurationPresentationSource
    {
        const reference = ApiAdapter.ConvertBackendProductConfigurationReference(backendPresentationSource.reference);
        const templateFields = ApiAdapter.ConvertBackendTemplateFields(backendPresentationSource.template_fields);
        
        return new ProductConfigurationPresentationSource(reference, templateFields);
    }


    public static ConvertBackendProductConfigurationIdeas(backendIdeas : ServerApi.ProductConfigurationIdea[]) : ProductConfigurationIdea[]
    {
        return backendIdeas.map(ApiAdapter.ConvertBackendProductConfigurationIdea);
    }


    public static ConvertBackendProductConfigurationIdea(backendIdea : ServerApi.ProductConfigurationIdea) : ProductConfigurationIdea
    {
        const reference = ApiAdapter.ConvertBackendProductConfigurationReference(backendIdea.reference);
        const inspiration = backendIdea.inspiration && ApiAdapter.ConvertBackendInspiration(backendIdea.inspiration);
        const renderings = ApiAdapter.ConvertBackendRenderings(backendIdea.renderings);
        
        return new ProductConfigurationIdea(reference, inspiration, renderings);
    }


    public static ConvertBackendProductConfigurationReference(backendReference : ServerApi.ProductConfigurationReference) : ProductConfigurationReference
    {
        const { definition, definition_hash, name } = backendReference;

        return new ProductConfigurationReference(definition, definition_hash, name);
    }


    public static ConvertProductConfigurationReference(reference : ProductConfigurationReference) : ServerApi.ProductConfigurationReference
    {
        return {
            definition: reference.Definition,
            definition_hash: reference.DefinitionHash,
            name: reference.Name  
        };
    }


    public static ConvertBackendInspiration(inspiration : ServerApi.Inspiration) : Inspiration
    {
        return {
            backgroundColor: inspiration.background_color_hex_rgba ? new Color(inspiration.background_color_hex_rgba) : null,
            productColors: inspiration.product_colors_hex_rgba.map(colorHexRgba => new Color(colorHexRgba)),
            imageUrl: inspiration.image_url || null
        };
    }


    public static ConvertProjectFonts(fontTags : TemplateFontTag[]) : ServerApi.TemplateFontTag[]
    {
        return fontTags.map(ApiAdapter.ConvertProjectFont);
    }


    public static ConvertBackendProjectFonts(fontTags : ServerApi.TemplateFontTag[]) : TemplateFontTag[]
    {
        return fontTags.map(ApiAdapter.ConvertBackendProjectFont);
    }


    private static ConvertBackendRenderings(backendRenderings : ServerApi.ProductConfigurationRenderings) : Rendering[]
    {
        const renderings : Rendering[] = [];

        for(const cameraId in backendRenderings)
        {
            const backendFiles = backendRenderings[cameraId];
            const files = backendFiles.map(backendFile =>
            ({
                extension: backendFile.extension,
                sizeCode: backendFile.size_code,
                url: backendFile.url
            }));

            const rendering = new Rendering(cameraId, files);
            renderings.push(rendering);
        }

        return renderings;
    }


    private static ConvertBackendTemplateFields(backendMappings : ServerApi.TemplateField[]) : TemplateTag[]
    {
        return backendMappings.map(ApiAdapter.ConvertBackendTemplateField);
    }


    private static ConvertBackendTemplateField(backendMapping : ServerApi.TemplateField) : TemplateTag
    {
        const templateTagFactory = new TemplateTagFactory();

        const templateTag = templateTagFactory.createTemplateTagFromBackend(backendMapping);
        return templateTag;
    }


    private static ConvertBackendAvailableFont(backendFont : ServerApi.Font) : Font
    {
        return new Font(backendFont.type_face, backendFont.url);
    }


    private static ConvertProjectFont(font : TemplateFontTag) : ServerApi.TemplateFontTag
    {
        return {
            tag_name: font.tagName,
            type_face: font.typeFace
        };
    }


    private static ConvertBackendProjectFont(font : ServerApi.TemplateFontTag) : TemplateFontTag
    {
        return {
            tagName: font.tag_name,
            typeFace: font.type_face 
        };
    }
}