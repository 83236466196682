import { ColorsListBackgroundOrConfigurationItemProps } from "./colors-list-background-or-configuration-item-props";
import ColorsListItem from "./colors-list-item"


const ColorsListConfigurationItem = (props : ColorsListBackgroundOrConfigurationItemProps) =>
{
    const iconUrl = "/images/icon-brush.png";
    const iconAltText = "Konfigurationsfarben-Icon";
    const invertIconColor = (props.color.luminosity() < 0.5);

    return <ColorsListItem color={props.color} iconUrl={iconUrl} iconAltText={iconAltText} drawBorder={true} invertIconColor={invertIconColor} />;
}


export default ColorsListConfigurationItem;